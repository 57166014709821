import { atom } from 'recoil';

import { countries } from '@apps/web/entities/countries';
import { nationalities } from '@apps/web/entities/nationalities';
import { PreferredLanguage } from '@apps/web/lib/types';

/**
 * TODO: Refactor @apps/web/entities to a single object
 */
const defaultOnboardingValues = {
	nationality: nationalities.find((nation) => nation.en === 'German').value,
	country: countries.find((nation) => nation.en === 'Germany').value,
	countryCode: 49,
};

const KEY = 'createAccount';
export const createAccount = atom({
	key: KEY,
	default: {
		firstNames: '',
		surname: '',
		day: '',
		month: '',
		year: '',
		street: '',
		zipCode: '',
		city: '',
		country: defaultOnboardingValues.country || '',
		nationality: defaultOnboardingValues.nationality || '',
		preferredLanguage: PreferredLanguage.German,
		countryCode: defaultOnboardingValues.countryCode,
		phoneNumber: '',
		dataProtection: false,
		generalTerms: false,
		newsletterConfirmation: false,
		gender: '',
		height: '',
		weight: '',
		pregnant_or_breastfeeding: [] as string[],
	},
	effects_UNSTABLE: [
		({ setSelf, onSet }) => {
			const savedValue = localStorage.getItem(KEY);
			if (savedValue != null) {
				setSelf(JSON.parse(savedValue));
			}

			onSet((newValue) => {
				localStorage.setItem(KEY, JSON.stringify(newValue));
			});
		},
	],
});

export const clearAccountData = () => {
	// only remove the createAccount state
	if (localStorage.getItem(KEY)) {
		localStorage.removeItem(KEY);
	}
};

import { i18n, Messages } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { de, en } from 'make-plural/plurals';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';
import TagManager from 'react-gtm-module';
import { RecoilRoot } from 'recoil';

import { AuthProvider } from '@apps/web/contexts/AuthContext';
import { trackLanguageChange, trackPageview } from '@apps/web/utils/gtm';

import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'react-big-calendar/lib/sass/styles.scss';
import * as fbq from '@apps/web/lib/fbpixel';

import '../styles/globals.css';
import Script from 'next/script';

i18n.loadLocaleData('en', { plurals: en });
i18n.loadLocaleData('de', { plurals: de });

type Props = {
	translations?: Messages;
	data: {
		pageData: {
			title: string;
			description?: string;
		};
	};
};

function MyApp({ Component, pageProps, router }: AppProps<Props>) {
	const { locale } = useRouter();

	useEffect(() => {
		fbq.pageView();

		const handleRouteChange = () => {
			fbq.pageView();
		};

		router.events.on('routeChangeComplete', handleRouteChange);

		return () => {
			router.events.off('routeChangeComplete', handleRouteChange);
		};
	}, [router.events]);


	// restore scroll on page change and on initial load
	useEffect(() => {
		if (typeof window !== 'undefined') {
			window.history.scrollRestoration = 'manual';
			window.scrollTo({ top: 0, left: 0 });
		}

		router.events.on('routeChangeComplete', () => window.scrollTo({ top: 0, left: 0 }));

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// TODO: Wrap this so we allow `pageProps.translations` to be empty, and fallback to a clientside solution
	const firstRender = useRef(true);
	if (pageProps.translations && firstRender.current && locale) {
		// load the translations for the locale
		i18n.load(locale, pageProps.translations);
		i18n.activate(locale);
		// render only once
		firstRender.current = false;
	}

	// TODO: Wrap this so we allow `pageProps.translations` to be empty, and fallback to a clientside solution
	// listen for the locale changes
	useEffect(() => {
		if (pageProps.translations && locale) {
			i18n.load(locale, pageProps.translations);
			i18n.activate(locale);
		}
	}, [locale, pageProps.translations]);

	// GTM language change
	useEffect(() => {
		trackLanguageChange(locale?.toUpperCase() || 'EN');
	}, [locale]);

	// GTM page view
	useEffect(() => {
		router.events.on('routeChangeComplete', trackPageview);
		return () => {
			router.events.off('routeChangeComplete', trackPageview);
		};
	}, [router.events]);

	useEffect(() => {
		if (process.env.GTM_ID && typeof process.env.GTM_ID === 'string') {
			TagManager.initialize({ gtmId: process.env.GTM_ID });
		}

		return () => {};
	}, []);

	return (
		<>
			<Head>
				<title>{[pageProps?.data?.pageData?.title, 'Canify Clinics'].filter((v) => !!v).join(' - ')}</title>
				<meta name="description" content={pageProps?.data?.pageData?.description} />

				<link rel="apple-touch-icon" sizes="180x180" href="favicons/apple-touch-icon.png?v=2" />
				<link rel="icon" type="image/png" sizes="32x32" href="favicons/favicon-32x32.png?v=2" />
				<link rel="icon" type="image/png" sizes="16x16" href="favicons/favicon-16x16.png?v=2" />
				<link rel="mask-icon" href="favicons/safari-pinned-tab.svg?v=2" color="#17335b" />
				<meta name="msapplication-TileColor" content="#17335b" />
				<meta name="theme-color" content="#17335b" />
				{fbq.FB_DOMAIN_VERIFICATION && (
					<meta name="facebook-domain-verification" content={fbq.FB_DOMAIN_VERIFICATION} />
				)}
			</Head>

			{fbq.FB_PIXEL_ID && (
				<Script
					id='fb-pixel'
					strategy='afterInteractive'
					dangerouslySetInnerHTML={{
						__html: `
						!function(f,b,e,v,n,t,s)
						{if(f.fbq) return;n=f.fbq=function(){n.callMethod?
						n.callMethod.apply(n,arguments):n.queue.push(arguments)};
						if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
						n.queue=[];t=b.createElement(e);t.async=!0;
						t.src=v;s=b.getElementsByTagName(e)[0];
						s.parentNode.insertBefore(t,s) }(window,document,'script',
						'https://connect.facebook.net/en_US/fbevents.js');
						fbq('init', ${fbq.FB_PIXEL_ID});
					`,
					}}
				/>
			)}

			<AuthProvider>
				<I18nProvider i18n={i18n}>
					<style jsx global>
						{`
							@font-face {
								font-family: 'Gotham';
								font-display: swap;
								font-style: normal;
								font-weight: 400;
								src: url('/fonts/Gotham-Book_Web.woff2') format('woff2');
							}

							@font-face {
								font-family: 'Gotham';
								font-display: swap;
								font-style: normal;
								font-weight: 500;
								src: url('/fonts/Gotham-Medium_Web.woff2') format('woff2');
							}

							@font-face {
								font-family: 'Gotham';
								font-display: swap;
								font-style: normal;
								font-weight: 700;
								src: url('/fonts/Gotham-Bold_Web.woff2') format('woff2');
							}

							@font-face {
								font-family: 'Gotham';
								font-display: swap;
								font-style: normal;
								font-weight: 900;
								src: url('/fonts/Gotham-Ultra_Web.woff2') format('woff2');
							}

							@font-face {
								font-family: 'Gotham Ultra';
								font-display: swap;
								font-style: normal;
								font-weight: 900;
								src: url('/fonts/Gotham-Ultra_Web.woff2') format('woff2');
							}
						`}
					</style>
					<RecoilRoot>
						<Component {...pageProps} />
					</RecoilRoot>
				</I18nProvider>
			</AuthProvider>
		</>
	);
}

export default MyApp;

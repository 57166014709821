export function trackLanguageChange(language: string) {
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	window?.dataLayer?.push({
		event: 'changeLanguage',
		language,
	});
}

export function trackPageview(url: string) {
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	window?.dataLayer?.push({
		event: 'pageview',
		page: url,
	});
}

export const countries = [
	{ en: 'Afghanistan', de: 'Afghanistan', value: 'Afghanistan' },
	{ en: 'Albania', de: 'Albanien', value: 'Albania' },
	{ en: 'Algeria', de: 'Algerien', value: 'Algeria' },
	{ en: 'Andorra', de: 'Andorra', value: 'Andorra' },
	{ en: 'Angola', de: 'Angola', value: 'Angola' },
	{
		en: 'Antigua and Barbuda',
		de: 'Antigua und Barbuda',
		value: 'Antigua and Barbuda',
	},
	{ en: 'Argentina', de: 'Argentinien', value: 'Argentina' },
	{ en: 'Armenia', de: 'Armenien', value: 'Armenia' },
	{ en: 'Australia', de: 'Australien', value: 'Australia' },
	{ en: 'Austria', de: 'Österreich', value: 'Austria' },
	{ en: 'Azerbaijan', de: 'Aserbaidschan', value: 'Azerbaijan' },
	{ en: 'Bahamas', de: 'Bahamas', value: 'Bahamas' },
	{ en: 'Bahrain', de: 'Bahrain', value: 'Bahrain' },
	{ en: 'Bangladesh', de: 'Bangladesch', value: 'Bangladesh' },
	{ en: 'Barbados', de: 'Barbados', value: 'Barbados' },
	{ en: 'Belarus', de: 'Belarus', value: 'Belarus' },
	{ en: 'Belgium', de: 'Belgien', value: 'Belgium' },
	{ en: 'Belize', de: 'Belize', value: 'Belize' },
	{ en: 'Benin', de: 'Benin', value: 'Benin' },
	{ en: 'Bhutan', de: 'Bhutan', value: 'Bhutan' },
	{
		en: 'Bolivia (Plurinational State of)',
		de: 'Bolivien',
		value: 'Bolivia (Plurinational State of)',
	},
	{
		en: 'Bosnia and Herzegovina',
		de: 'Bosnien und Herzegowina',
		value: 'Bosnia and Herzegovina',
	},
	{ en: 'Botswana', de: 'Botswana', value: 'Botswana' },
	{ en: 'Brazil', de: 'Brasilien', value: 'Brazil' },
	{ en: 'Brunei Darussalam', de: 'Brunei', value: 'Brunei Darussalam' },
	{ en: 'Bulgaria', de: 'Bulgarien', value: 'Bulgaria' },
	{ en: 'Burkina Faso', de: 'Burkina Faso', value: 'Burkina Faso' },
	{ en: 'Burundi', de: 'Burundi', value: 'Burundi' },
	{ en: 'Cabo Verde', de: 'Kap Verde', value: 'Cabo Verde' },
	{ en: 'Cambodia', de: 'Kambodscha', value: 'Cambodia' },
	{ en: 'Cameroon', de: 'Kamerun', value: 'Cameroon' },
	{ en: 'Canada', de: 'Kanada', value: 'Canada' },
	{
		en: 'Central African Republic',
		de: 'Zentralafrikanische Republik',
		value: 'Central African Republic',
	},
	{ en: 'Chad', de: 'Tschad', value: 'Chad' },
	{ en: 'Chile', de: 'Chile', value: 'Chile' },
	{ en: 'China', de: 'Volksrepublik China', value: 'China' },
	{ en: 'Colombia', de: 'Kolumbien', value: 'Colombia' },
	{ en: 'Comoros', de: 'Komoren', value: 'Comoros' },
	{ en: 'Congo', de: 'Kongo, Republik', value: 'Congo' },
	{
		en: 'Congo, Democratic Republic of the',
		de: 'Kongo, Demokratische Republik',
		value: 'Congo, Democratic Republic of the',
	},
	{ en: 'Costa Rica', de: 'Costa Rica', value: 'Costa Rica' },
	{ en: "Côte d'Ivoire", de: 'Elfenbeinküste', value: "Côte d'Ivoire" },
	{ en: 'Croatia', de: 'Kroatien', value: 'Croatia' },
	{ en: 'Cuba', de: 'Kuba', value: 'Cuba' },
	{ en: 'Cyprus', de: 'Zypern', value: 'Cyprus' },
	{ en: 'Czechia', de: 'Tschechien', value: 'Czechia' },
	{ en: 'Denmark', de: 'Dänemark', value: 'Denmark' },
	{ en: 'Djibouti', de: 'Dschibuti', value: 'Djibouti' },
	{ en: 'Dominica', de: 'Dominica', value: 'Dominica' },
	{
		en: 'Dominican Republic',
		de: 'Dominikanische Republik',
		value: 'Dominican Republic',
	},
	{ en: 'Ecuador', de: 'Ecuador', value: 'Ecuador' },
	{ en: 'Egypt', de: 'Ägypten', value: 'Egypt' },
	{ en: 'El Salvador', de: 'El Salvador', value: 'El Salvador' },
	{
		en: 'Equatorial Guinea',
		de: 'Äquatorialguinea',
		value: 'Equatorial Guinea',
	},
	{ en: 'Eritrea', de: 'Eritrea', value: 'Eritrea' },
	{ en: 'Estonia', de: 'Estland', value: 'Estonia' },
	{ en: 'Eswatini', de: 'Eswatini', value: 'Eswatini' },
	{ en: 'Ethiopia', de: 'Äthiopien', value: 'Ethiopia' },
	{ en: 'Fiji', de: 'Fidschi', value: 'Fiji' },
	{ en: 'Finland', de: 'Finnland', value: 'Finland' },
	{ en: 'France', de: 'Frankreich', value: 'France' },
	{ en: 'Gabon', de: 'Gabun', value: 'Gabon' },
	{ en: 'Gambia', de: 'Gambia', value: 'Gambia' },
	{ en: 'Georgia', de: 'Georgien', value: 'Georgia' },
	{ en: 'Germany', de: 'Deutschland', value: 'Germany' },
	{ en: 'Ghana', de: 'Ghana', value: 'Ghana' },
	{ en: 'Greece', de: 'Griechenland', value: 'Greece' },
	{ en: 'Grenada', de: 'Grenada', value: 'Grenada' },
	{ en: 'Guatemala', de: 'Guatemala', value: 'Guatemala' },
	{ en: 'Guinea', de: 'Guinea', value: 'Guinea' },
	{ en: 'Guinea-Bissau', de: 'Guinea-Bissau', value: 'Guinea-Bissau' },
	{ en: 'Guyana', de: 'Guyana', value: 'Guyana' },
	{ en: 'Haiti', de: 'Haiti', value: 'Haiti' },
	{ en: 'Honduras', de: 'Honduras', value: 'Honduras' },
	{ en: 'Hungary', de: 'Ungarn', value: 'Hungary' },
	{ en: 'Iceland', de: 'Island', value: 'Iceland' },
	{ en: 'India', de: 'Indien', value: 'India' },
	{ en: 'Indonesia', de: 'Indonesien', value: 'Indonesia' },
	{
		en: 'Iran (Islamic Republic of)',
		de: 'Iran',
		value: 'Iran (Islamic Republic of)',
	},
	{ en: 'Iraq', de: 'Irak', value: 'Iraq' },
	{ en: 'Ireland', de: 'Irland', value: 'Ireland' },
	{ en: 'Israel', de: 'Israel', value: 'Israel' },
	{ en: 'Italy', de: 'Italien', value: 'Italy' },
	{ en: 'Jamaica', de: 'Jamaika', value: 'Jamaica' },
	{ en: 'Japan', de: 'Japan', value: 'Japan' },
	{ en: 'Jordan', de: 'Jordanien', value: 'Jordan' },
	{ en: 'Kazakhstan', de: 'Kasachstan', value: 'Kazakhstan' },
	{ en: 'Kenya', de: 'Kenia', value: 'Kenya' },
	{ en: 'Kiribati', de: 'Kiribati', value: 'Kiribati' },
	{
		en: "Korea (Democratic People's Republic of)",
		de: 'Korea, Nord',
		value: "Korea (Democratic People's Republic of)",
	},
	{ en: 'Korea, Republic of', de: 'Korea, Süd', value: 'Korea, Republic of' },
	{ en: 'Kuwait', de: 'Kuwait', value: 'Kuwait' },
	{ en: 'Kyrgyzstan', de: 'Kirgisistan', value: 'Kyrgyzstan' },
	{
		en: "Lao People's Democratic Republic",
		de: 'Laos',
		value: "Lao People's Democratic Republic",
	},
	{ en: 'Latvia', de: 'Lettland', value: 'Latvia' },
	{ en: 'Lebanon', de: 'Libanon', value: 'Lebanon' },
	{ en: 'Lesotho', de: 'Lesotho', value: 'Lesotho' },
	{ en: 'Liberia', de: 'Liberia', value: 'Liberia' },
	{ en: 'Libya', de: 'Libyen', value: 'Libya' },
	{ en: 'Liechtenstein', de: 'Liechtenstein', value: 'Liechtenstein' },
	{ en: 'Lithuania', de: 'Litauen', value: 'Lithuania' },
	{ en: 'Luxembourg', de: 'Luxemburg', value: 'Luxembourg' },
	{ en: 'Madagascar', de: 'Madagaskar', value: 'Madagascar' },
	{ en: 'Malawi', de: 'Malawi', value: 'Malawi' },
	{ en: 'Malaysia', de: 'Malaysia', value: 'Malaysia' },
	{ en: 'Maldives', de: 'Malediven', value: 'Maldives' },
	{ en: 'Mali', de: 'Mali', value: 'Mali' },
	{ en: 'Malta', de: 'Malta', value: 'Malta' },
	{ en: 'Marshall Islands', de: 'Marshallinseln', value: 'Marshall Islands' },
	{ en: 'Mauritania', de: 'Mauretanien', value: 'Mauritania' },
	{ en: 'Mauritius', de: 'Mauritius', value: 'Mauritius' },
	{ en: 'Mexico', de: 'Mexiko', value: 'Mexico' },
	{
		en: 'Micronesia (Federated States of)',
		de: 'Mikronesien',
		value: 'Micronesia (Federated States of)',
	},
	{ en: 'Moldova, Republic of', de: 'Moldau', value: 'Moldova, Republic of' },
	{ en: 'Monaco', de: 'Monaco', value: 'Monaco' },
	{ en: 'Mongolia', de: 'Mongolei', value: 'Mongolia' },
	{ en: 'Montenegro', de: 'Montenegro', value: 'Montenegro' },
	{ en: 'Morocco', de: 'Marokko', value: 'Morocco' },
	{ en: 'Mozambique', de: 'Mosambik', value: 'Mozambique' },
	{ en: 'Myanmar', de: 'Myanmar', value: 'Myanmar' },
	{ en: 'Namibia', de: 'Namibia', value: 'Namibia' },
	{ en: 'Nauru', de: 'Nauru', value: 'Nauru' },
	{ en: 'Nepal', de: 'Nepal', value: 'Nepal' },
	{ en: 'Netherlands', de: 'Niederlande', value: 'Netherlands' },
	{ en: 'New Zealand', de: 'Neuseeland', value: 'New Zealand' },
	{ en: 'Nicaragua', de: 'Nicaragua', value: 'Nicaragua' },
	{ en: 'Niger', de: 'Niger', value: 'Niger' },
	{ en: 'Nigeria', de: 'Nigeria', value: 'Nigeria' },
	{ en: 'North Macedonia', de: 'Nordmazedonien', value: 'North Macedonia' },
	{ en: 'Norway', de: 'Norwegen', value: 'Norway' },
	{ en: 'Oman', de: 'Oman', value: 'Oman' },
	{ en: 'Pakistan', de: 'Pakistan', value: 'Pakistan' },
	{ en: 'Palau', de: 'Palau', value: 'Palau' },
	{ en: 'Panama', de: 'Panama', value: 'Panama' },
	{ en: 'Papua New Guinea', de: 'Papua-Neuguinea', value: 'Papua New Guinea' },
	{ en: 'Paraguay', de: 'Paraguay', value: 'Paraguay' },
	{ en: 'Peru', de: 'Peru', value: 'Peru' },
	{ en: 'Philippines', de: 'Philippinen', value: 'Philippines' },
	{ en: 'Poland', de: 'Polen', value: 'Poland' },
	{ en: 'Portugal', de: 'Portugal', value: 'Portugal' },
	{ en: 'Qatar', de: 'Katar', value: 'Qatar' },
	{ en: 'Romania', de: 'Rumänien', value: 'Romania' },
	{ en: 'Russian Federation', de: 'Russland', value: 'Russian Federation' },
	{ en: 'Rwanda', de: 'Ruanda', value: 'Rwanda' },
	{
		en: 'Saint Kitts and Nevis',
		de: 'St. Kitts und Nevis',
		value: 'Saint Kitts and Nevis',
	},
	{ en: 'Saint Lucia', de: 'St. Lucia', value: 'Saint Lucia' },
	{
		en: 'Saint Vincent and the Grenadines',
		de: 'St. Vincent und die Grenadinen',
		value: 'Saint Vincent and the Grenadines',
	},
	{ en: 'Samoa', de: 'Samoa', value: 'Samoa' },
	{ en: 'San Marino', de: 'San Marino', value: 'San Marino' },
	{
		en: 'Sao Tome and Principe',
		de: 'São Tomé und Príncipe',
		value: 'Sao Tome and Principe',
	},
	{ en: 'Saudi Arabia', de: 'Saudi-Arabien', value: 'Saudi Arabia' },
	{ en: 'Senegal', de: 'Senegal', value: 'Senegal' },
	{ en: 'Serbia', de: 'Serbien', value: 'Serbia' },
	{ en: 'Seychelles', de: 'Seychellen', value: 'Seychelles' },
	{ en: 'Sierra Leone', de: 'Sierra Leone', value: 'Sierra Leone' },
	{ en: 'Singapore', de: 'Singapur', value: 'Singapore' },
	{ en: 'Slovakia', de: 'Slowakei', value: 'Slovakia' },
	{ en: 'Slovenia', de: 'Slowenien', value: 'Slovenia' },
	{ en: 'Solomon Islands', de: 'Salomonen', value: 'Solomon Islands' },
	{ en: 'Somalia', de: 'Somalia', value: 'Somalia' },
	{ en: 'South Africa', de: 'Südafrika', value: 'South Africa' },
	{ en: 'South Sudan', de: 'Südsudan', value: 'South Sudan' },
	{ en: 'Spain', de: 'Spanien', value: 'Spain' },
	{ en: 'Sri Lanka', de: 'Sri Lanka', value: 'Sri Lanka' },
	{ en: 'Sudan', de: 'Sudan', value: 'Sudan' },
	{ en: 'Suriname', de: 'Suriname', value: 'Suriname' },
	{ en: 'Sweden', de: 'Schweden', value: 'Sweden' },
	{ en: 'Switzerland', de: 'Schweiz', value: 'Switzerland' },
	{ en: 'Syrian Arab Republic', de: 'Syrien', value: 'Syrian Arab Republic' },
	{ en: 'Tajikistan', de: 'Tadschikistan', value: 'Tajikistan' },
	{
		en: 'Tanzania, United Republic of',
		de: 'Tansania',
		value: 'Tanzania, United Republic of',
	},
	{ en: 'Thailand', de: 'Thailand', value: 'Thailand' },
	{ en: 'Timor-Leste', de: 'Osttimor', value: 'Timor-Leste' },
	{ en: 'Togo', de: 'Togo', value: 'Togo' },
	{ en: 'Tonga', de: 'Tonga', value: 'Tonga' },
	{
		en: 'Trinidad and Tobago',
		de: 'Trinidad und Tobago',
		value: 'Trinidad and Tobago',
	},
	{ en: 'Tunisia', de: 'Tunesien', value: 'Tunisia' },
	{ en: 'Turkey', de: 'Türkei', value: 'Turkey' },
	{ en: 'Turkmenistan', de: 'Turkmenistan', value: 'Turkmenistan' },
	{ en: 'Tuvalu', de: 'Tuvalu', value: 'Tuvalu' },
	{ en: 'Uganda', de: 'Uganda', value: 'Uganda' },
	{ en: 'Ukraine', de: 'Ukraine', value: 'Ukraine' },
	{
		en: 'United Arab Emirates',
		de: 'Vereinigte Arabische Emirate',
		value: 'United Arab Emirates',
	},
	{
		en: 'United Kingdom of Great Britain and Northern Ireland',
		de: 'Vereinigtes Königreich',
		value: 'United Kingdom of Great Britain and Northern Ireland',
	},
	{
		en: 'United States of America',
		de: 'Vereinigte Staaten',
		value: 'United States of America',
	},
	{ en: 'Uruguay', de: 'Uruguay', value: 'Uruguay' },
	{ en: 'Uzbekistan', de: 'Usbekistan', value: 'Uzbekistan' },
	{ en: 'Vanuatu', de: 'Vanuatu', value: 'Vanuatu' },
	{
		en: 'Venezuela (Bolivarian Republic of)',
		de: 'Venezuela',
		value: 'Venezuela (Bolivarian Republic of)',
	},
	{ en: 'Viet Nam', de: 'Vietnam', value: 'Viet Nam' },
	{ en: 'Yemen', de: 'Jemen', value: 'Yemen' },
	{ en: 'Zambia', de: 'Sambia', value: 'Zambia' },
	{ en: 'Zimbabwe', de: 'Simbabwe', value: 'Zimbabwe' },
];

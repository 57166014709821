import React, { ReactNode, useContext, useEffect, useMemo, useState } from 'react';

import { AccountService, AccountUserType } from '@apps/web/services/AccountService';

import { UserRoles } from '../lib/types';

export type AuthContextType = {
	isAuthenticated: boolean;
	user: AccountUserType | undefined; // TODO get structured json from the backend and make a user type
	isLoading: boolean;
	isAdmin: boolean;
	isSupport: boolean;
	isPatient: boolean;
	isDoctor: boolean;
};

export const AuthContext = React.createContext<AuthContextType>({
	isAuthenticated: false,
	user: undefined,
	isLoading: true,
	isAdmin: false,
	isSupport: false,
	isPatient: false,
	isDoctor: false,
});

export function useAuth() : AuthContextType {
	const context = useContext(AuthContext);

	if (context === undefined) {
		throw new Error('useAuth must be used within an AuthProvider');
	}

	return context;
}

export type AuthProviderProps = {
	children: ReactNode;
};

export const AuthProvider = ({ children }: AuthProviderProps) => {
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [user, setUser] = useState<AccountUserType | undefined>();
	const [isLoading, setIsLoading] = useState(true);
	const isAdmin = useMemo(() => user && [UserRoles.Admin].includes(user?.extension_UserRole as UserRoles), [user]);
	const isSupport = useMemo(() => user && [UserRoles.Support, UserRoles.Admin].includes(user?.extension_UserRole as UserRoles), [user]);
	const isPatient = useMemo(() => user && [UserRoles.Patient].includes(user?.extension_UserRole as UserRoles), [user]);
	const isDoctor = useMemo(() => user && ['Doctor'].includes(user?.extension_UserRole), [user]);

	const getUser = async () => {
		const user = await AccountService.getUser();

		if (!user) {
			setIsAuthenticated(false);
		} else {
			setIsAuthenticated(true);
			setUser(user);
		}

		setIsLoading(false);
	};

	useEffect(
		() => {
			getUser().then();

			return () => {};
		},
		[],
	);

	return (
		<AuthContext.Provider
			value={{
				isAuthenticated,
				user,
				isLoading,
				isAdmin,
				isSupport,
				isPatient,
				isDoctor,
			}}
			>
			{children}
		</AuthContext.Provider>
		);
};

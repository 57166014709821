/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AddCommentDto {
	comment?: string | null;
}

export interface AllowedReservationTypesDto {
	canBookNewPatientBooking?: boolean;
	canBookFollowup?: boolean;
	canBookYearlyCheck?: boolean;
	canBookPrescription?: boolean;
	canBookPreliminary?: boolean;
}

export interface AppointmentCommentDto {
	/** @format uuid */
	id?: string;
	comment?: string | null;
	author?: string | null;

	/** @format date-time */
	createdUtc?: string;
}

export interface AppointmentDto {
	reservation?: ReservationDetailsDto;
	patientArrived?: boolean;
	consultationCompleted?: boolean;
	invoice?: boolean;
	noShow?: boolean;

	/** @format int32 */
	commentCount?: number;
	lastComment?: AppointmentCommentDto;
}

export interface AvailableDay {
	/** @format date-time */
	date?: string;
	timeSlots?: AvailableTimeSlot[] | null;
}

export interface AvailableTimeSlot {
	/** @format date-time */
	startMeeting?: string;

	/** @format date-time */
	endMeeting?: string;
	allowOnline?: boolean;
	allowPhysical?: boolean;
	allowedByPatient?: boolean;
}

export interface CheckInDto {
	/** @format uuid */
	id?: string;

	/** @format int32 */
	userId?: number;

	/** @format int32 */
	weekNumber?: number;

	/** @format date-time */
	createdAt?: string;

	/** @format int32 */
	pain?: number;

	/** @format int32 */
	mood?: number;

	/** @format int32 */
	qualityOfLife?: number;

	/** @format int32 */
	appetite?: number;

	/** @format int32 */
	physicalActivity?: number;

	/** @format int32 */
	concentration?: number;

	/** @format int32 */
	sleep?: number;
}

export interface ClinicCalendarDto {
	/** @format uuid */
	id?: string;
	name?: string | null;
	city?: string | null;
	street?: string | null;
	postalCode?: string | null;
	buildingNumber?: string | null;
	apartmentNumber?: string | null;
	phoneNumber?: string | null;
	email?: string | null;
}

export interface ClinicCalendarListModel {
	clinic?: ClinicCalendarDto;
	doctors?: DoctorCalendarDto[] | null;
}

export interface ClinicDto {
	/** @format uuid */
	id?: string;
	name?: string | null;
	city?: string | null;
	street?: string | null;
	postalCode?: string | null;
	buildingNumber?: string | null;
	apartmentNumber?: string | null;
	email?: string | null;
}

export interface ClinicKeyValueDto {
	/** @format uuid */
	id?: string;
	name?: string | null;
}

export interface CreateCheckInDto {
	/** @format int32 */
	pain?: number;

	/** @format int32 */
	mood?: number;

	/** @format int32 */
	qualityOfLife?: number;

	/** @format int32 */
	appetite?: number;

	/** @format int32 */
	physicalActivity?: number;

	/** @format int32 */
	concentration?: number;

	/** @format int32 */
	sleep?: number;
}

export interface CreateClinicDto {
	/** @format uuid */
	id?: string;
	name: string;
	city: string;
	street: string;
	postalCode?: string | null;
	buildingNumber?: string | null;
	apartmentNumber?: string | null;

	/** @format email */
	email?: string | null;
	phoneNumber?: string | null;
}

export interface CreateDoctorAvailabilityDto {
	/** @format uuid */
	id?: string;

	/** @format uuid */
	doctorId: string;

	/** @format uuid */
	clinicId: string;

	/** @format date-time */
	startEvent: string;

	/** @format date-time */
	endEvent: string;
	newPatientBooking?: boolean;
	followup?: boolean;
	prescription?: boolean;
	yearlyCheck?: boolean;
	allowOnline?: boolean;
	allowPhysical?: boolean;
}

export interface CreateDoctorDto {
	/** @format uuid */
	id?: string;
	firstName: string;
	lastName: string;

	/** @format uuid */
	clinicId: string;
	degree?: string | null;
}

export interface CreateReservationDto {
	/** @format uuid */
	id?: string;

	/** @format uuid */
	doctorId: string;

	/** @format uuid */
	clinicId: string;
	isOnline: boolean;
	timeSlot: TimeSlot;
	reservationType: ReservationType;
}

export interface CreateSaveForLater {
	url: string;
	json: string;
}

export interface CreateSupportReservationDto {
	/** @format uuid */
	doctorId: string;

	/** @format uuid */
	clinicId: string;

	/** @format int32 */
	userId: number;
	isOnline: boolean;
	reservationType: ReservationType;

	/** @format date-time */
	startMeeting: string;

	/** @format date-time */
	endMeeting: string;
}

export interface DoctorAvailableSlot {
	doctor?: DoctorDto;
	clinic?: ClinicDto;
	availableDays?: AvailableDay[] | null;
}

export interface DoctorCalendarDto {
	/** @format uuid */
	id?: string;
	firstName?: string | null;
	degree?: string | null;
	lastName?: string | null;
}

export interface DoctorDto {
	/** @format uuid */
	id?: string;
	firstName?: string | null;
	lastName?: string | null;
	degree?: string | null;
}

export interface DoctorSupportAvailabilityDto {
	/** @format uuid */
	id?: string;

	/** @format date-time */
	startAvailableHours?: string;

	/** @format date-time */
	endAvailableHours?: string;
	newPatientBooking?: boolean;
	followup?: boolean;
	prescription?: boolean;
	yearlyCheck?: boolean;
	allowOnline?: boolean;
	allowPhysical?: boolean;
}

export interface DocumentDto {
	/** @format uuid */
	id?: string;
	documentType?: string | null;
	contentType?: string | null;
	fileName?: string | null;
	displayName?: string | null;

	/** @format date-time */
	uploadDate?: string;
	uploadedBySupport?: boolean;
	uploadedBy?: string | null;
	isRead?: boolean;
}

export interface DocumentListEntry {
	/** @format uuid */
	id?: string;

	/** @format int32 */
	memberId?: number;
	documentType?: string | null;
	fileName?: string | null;
	displayName?: string | null;
	mimeType?: string | null;

	/** @format date-time */
	uploadDate?: string | null;
	uploadedBySupport?: boolean;
	uploadedBy?: string | null;
	isRead?: boolean;
}

export enum ErrorCode {
	OnlyReservationOwnerCanDeleteThem = 'OnlyReservationOwnerCanDeleteThem',
	CanNotDeleteReservationLaterThan = 'CanNotDeleteReservationLaterThan',
	TooManyReservationAtOnce = 'TooManyReservationAtOnce',
	LeaveGapBetweenReservations = 'LeaveGapBetweenReservations',
	PossibleReservationBeforeNumberOfDays = 'PossibleReservationBeforeNumberOfDays',
	NewPatientBookingCanNotBeOnline = 'NewPatientBookingCanNotBeOnline',
	YearlyCheckCanNotBeOnline = 'YearlyCheckCanNotBeOnline',
	PreliminaryCanNotBeOnline = 'PreliminaryCanNotBeOnline',
	PendingAndIncompleteCanNotBookReservations = 'PendingAndIncompleteCanNotBookReservations',
	NoFirstBookingCanOnlyGetNewPatientBooking = 'NoFirstBookingCanOnlyGetNewPatientBooking',
	OtherStatusThanNoFirstBookingCanNotGetNewPatientBooking = 'OtherStatusThanNoFirstBookingCanNotGetNewPatientBooking',
	NoFirstBookingCanOnlyBookNewPatientBooking = 'NoFirstBookingCanOnlyBookNewPatientBooking',
	NewPatientBookingCanBeOnlyReservedByNoFirstBooking = 'NewPatientBookingCanBeOnlyReservedByNoFirstBooking',
	VisitAtThisHoursDoesNotExist = 'VisitAtThisHoursDoesNotExist',
	FailedToCreateVideoMeeting = 'FailedToCreateVideoMeeting',
	UserWithThisEmailAlreadyExists = 'UserWithThisEmailAlreadyExists',
	AvailabilityInThePastCanNotBeAdded = 'AvailabilityInThePastCanNotBeAdded',
	StartAvailabilityCanNotBeLaterThanEnd = 'StartAvailabilityCanNotBeLaterThanEnd',
	AvailabilityHasPartInCommonWithExisting = 'AvailabilityHasPartInCommonWithExisting',
	CanNotChangePendingStatusForPatientWithAllMedicalFlags = 'CanNotChangePendingStatusForPatientWithAllMedicalFlags',
	CanOnlyChangeToUnresolvedIfPendingOrIncomplete = 'CanOnlyChangeToUnresolvedIfPendingOrIncomplete',
	CanOnlyChangeToPendingOrIncompleteFromUnresolved = 'CanOnlyChangeToPendingOrIncompleteFromUnresolved',
	CanValidateOnlyWithPendingAndMedicalFlagsTrue = 'CanValidateOnlyWithPendingAndMedicalFlagsTrue',
	HealthCheckInForThisWeekAlreadyExists = 'HealthCheckInForThisWeekAlreadyExists',
	MemberNotFound = 'MemberNotFound',
	ReservationNotFound = 'ReservationNotFound',
	ClinicNotFound = 'ClinicNotFound',
	DoctorNotFound = 'DoctorNotFound',
	AvailabilityNotFound = 'AvailabilityNotFound',
	SaveForLaterNotFound = 'SaveForLaterNotFound',
	PossibleReservationDaysBeforeOnlyBusinessDays = 'PossibleReservationDaysBeforeOnlyBusinessDays',
	CannotDeleteDoctorWithAvailabilityOrReservations = 'CannotDeleteDoctorWithAvailabilityOrReservations',
	CannotDeleteClinicWithRelatedDoctors = 'CannotDeleteClinicWithRelatedDoctors',
	CannotDeleteClinicWithActiveAvailabilitiesOrReservations = 'CannotDeleteClinicWithActiveAvailabilitiesOrReservations',
	DoctorIsAlreadyDeleted = 'DoctorIsAlreadyDeleted',
	ClinicIsAlreadyDeleted = 'ClinicIsAlreadyDeleted',
	InvalidReservationTypeSelection = 'InvalidReservationTypeSelection',
	BookingNotAllowedForReservationType = 'BookingNotAllowedForReservationType',
	CannotEditAvailability = 'CannotEditAvailability',
	DoctorCannotEditReservationOfOtherDoctor = 'DoctorCannotEditReservationOfOtherDoctor',
	CannotSendDocumentOfSameTypeTwice = 'CannotSendDocumentOfSameTypeTwice',
	SupportMemberNotFound = 'SupportMemberNotFound',
	DocumentNotFound = 'DocumentNotFound',
	SupportNotAllowedToDeleteDocument = 'SupportNotAllowedToDeleteDocument',
	FileTooBig = 'FileTooBig',
	CannotAddEmptyFile = 'CannotAddEmptyFile',
	UnsupportedFileExtension = 'UnsupportedFileExtension',
	FileExistsAlready = 'FileExistsAlready',
	UnknownUploadError = 'UnknownUploadError',
}

export interface ErrorDetails {
	message?: string | null;
	code?: ErrorCode;
}

export interface MedicalHistoryDto {
	questionnaire?: any;

	/** @format uuid */
	clinicId?: string;
}

export interface MemberCommentDto {
	/** @format uuid */
	id?: string;
	comment?: string | null;
	author?: string | null;

	/** @format date-time */
	createdUtc?: string;
}

export interface MemberDetailsSupportDto {
	/** @format int32 */
	id?: number;

	/** @format int32 */
	tomedoId?: number | null;
	firstName?: string | null;
	lastName?: string | null;

	/** @format date-time */
	dateOfBirth?: string | null;
	phoneNumber?: string | null;
	street?: string | null;
	city?: string | null;
	zipCode?: string | null;
	country?: string | null;
	email?: string | null;
	preferredLanguage?: PreferredLanguage;
	nationality?: string | null;
	medicalInfoFlag?: boolean;
	medicalDocFlag?: boolean;
	hasUnreadMedicalDocs?: boolean;
	firstBookingFlag?: boolean;
	dataProtectionConfirmation?: boolean;
	generalTermsConfirmation?: boolean;
	correctInfoConfirmation?: boolean;
	status?: StatusType;
	checkInConfirmation?: boolean;
	canBookNewPatientBooking?: boolean;
	canBookFollowup?: boolean;
	canBookYearlyCheck?: boolean;
	canBookPrescription?: boolean;
	canBookPreliminary?: boolean;

	/** @format uuid */
	clinicId?: string | null;
	clinicName?: string | null;
	lastComment?: MemberCommentDto;

	/** @format int32 */
	commentCount?: number;

	/** @format date-time */
	createdUtc?: string;
	statusInfo?: StatusDto;
}

export interface MemberNotificationDto {
	/** @format int32 */
	id?: number;
	firstName?: string | null;
	lastName?: string | null;

	/** @format date-time */
	dateOfBirth?: string | null;
	phoneNumber?: string | null;
	street?: string | null;
	city?: string | null;
	zipCode?: string | null;
	country?: string | null;
	nationality?: string | null;
	preferredLanguage?: PreferredLanguage;
	email?: string | null;
	status?: StatusType;

	/** @format date-time */
	createdUtc?: string;

	/** @format date-time */
	lastLoginUtc?: string;
	senderMailOfClinic?: string | null;
	checkInConfirmation?: boolean;
}

export interface MemberProfileDto {
	/** @format int32 */
	id?: number;

	/** @format int32 */
	tomedoId?: number | null;
	firstName?: string | null;
	lastName?: string | null;

	/** @format date-time */
	dateOfBirth?: string | null;
	street?: string | null;
	zipCode?: string | null;
	city?: string | null;
	country?: string | null;
	email?: string | null;
	phoneNumber?: string | null;
	preferredLanguage?: PreferredLanguage;
	nationality?: string | null;
	medicalInfoFlag?: boolean;
	medicalDocFlag?: boolean;
	firstBookingFlag?: boolean;
	status?: StatusType;

	/** @format uuid */
	clinicId?: string | null;
	clinicName?: string | null;
}

export interface MemberSupportDto {
	/** @format int32 */
	id?: number;

	/** @format int32 */
	tomedoId?: number | null;
	firstName?: string | null;
	lastName?: string | null;
	phoneNumber?: string | null;
	email?: string | null;
	medicalInfoFlag?: boolean;
	medicalDocFlag?: boolean;
	hasUnreadMedicalDocs?: boolean;
	firstBookingFlag?: boolean;
	status?: StatusType;

	/** @format uuid */
	clinicId?: string | null;
	clinicName?: string | null;

	/** @format date-time */
	createdUtc?: string;
	statusInfo?: StatusDto;
}

export interface MemberSupportDtoPagedResponse {
	data?: MemberSupportDto[] | null;

	/** @format int32 */
	pageSize?: number | null;

	/** @format int32 */
	pageNumber?: number | null;

	/** @format int32 */
	totalNumberOfPages?: number;

	/** @format int32 */
	totalNumberOfPatients?: number;
}

export enum PreferredLanguage {
	English = 'English',
	German = 'German',
}

export interface ProblemDetails {
	type?: string | null;
	title?: string | null;

	/** @format int32 */
	status?: number | null;
	detail?: string | null;
	instance?: string | null;
}

export interface ReservationDetailsDto {
	/** @format uuid */
	id?: string;
	doctor?: DoctorDto;
	clinic?: ClinicDto;
	member?: MemberNotificationDto;
	isOnline?: boolean;
	videoMeetingUrl?: string | null;
	reservationType?: ReservationType;

	/** @format date-time */
	startMeeting?: string;

	/** @format date-time */
	endMeeting?: string;
}

export interface ReservationDto {
	/** @format uuid */
	id?: string;
	doctor?: DoctorDto;
	clinic?: ClinicDto;
	isOnline?: boolean;
	videoMeetingUrl?: string | null;
	reservationType?: ReservationType;

	/** @format date-time */
	startMeeting?: string;

	/** @format date-time */
	endMeeting?: string;
}

export enum ReservationRange {
	Upcoming = 'Upcoming',
	All = 'All',
	Past = 'Past',
}

export interface ReservationSupportDto {
	/** @format uuid */
	id?: string;
	doctor?: DoctorDto;
	clinic?: ClinicDto;
	isOnline?: boolean;
	videoMeetingUrl?: string | null;
	reservationType?: ReservationType;

	/** @format date-time */
	startMeeting?: string;

	/** @format date-time */
	endMeeting?: string;
}

export interface ReservationSupportModel {
	/** @format uuid */
	id?: string;
	member?: MemberNotificationDto;
	doctor?: DoctorDto;
	clinic?: ClinicDto;

	/** @format date-time */
	startMeeting?: string;

	/** @format date-time */
	endMeeting?: string;
	reservationType?: ReservationType;
	isOnline?: boolean;

	/** @format int32 */
	userId?: number;
	videoMeetingUrl?: string | null;
}

export enum ReservationType {
	Followup = 'Followup',
	NewPatientBooking = 'NewPatientBooking',
	Prescription = 'Prescription',
	YearlyCheck = 'YearlyCheck',
	InBetweenPrescription = 'InBetweenPrescription',
	Preliminary = 'Preliminary',
}

export interface ReservationTypeDto {
	reservationType?: ReservationType;

	/** @format int32 */
	lengthInMinutes?: number;
	warningMessage?: string | null;
}

export interface SaveForLaterDto {
	url?: string | null;
	json?: string | null;
}

export interface StatusDto {
	/** @format date-time */
	memberCreated?: string | null;

	/** @format date-time */
	medicalDocumentsUploaded?: string | null;

	/** @format date-time */
	medicalHistoryUploaded?: string | null;
}

export enum StatusType {
	Pending = 'Pending',
	Incomplete = 'Incomplete',
	Unresolved = 'Unresolved',
	NoFirstBooking = 'NoFirstBooking',
	Active = 'Active',
	Former = 'Former',
	Inactive = 'Inactive',
	Disapproved = 'Disapproved',
}

export interface SupportUserDetails {
	/** @format email */
	emailAddress: string;
	displayName: string;
	role: UserRoles;
}

export interface TimeSlot {
	/** @format date-time */
	startMeeting?: string;

	/** @format date-time */
	endMeeting?: string;
}

export interface TransferReservationDto {
	/** @format uuid */
	reservationId: string;

	/** @format uuid */
	newClinicId: string;

	/** @format uuid */
	newDoctorId: string;
}

export interface UpdateDoctorAvailability {
	/** @format date-time */
	startAvailableHours?: string;

	/** @format date-time */
	endAvailableHours?: string;
	newPatientBooking?: boolean;
	followup?: boolean;
	prescription?: boolean;
	yearlyCheck?: boolean;
	allowOnline?: boolean;
	allowPhysical?: boolean;
}

export interface UpdateDocumentDisplayNameDto {
	/** @format int32 */
	memberId?: number;

	/** @format uuid */
	documentId?: string;
	displayName?: string | null;
}

export interface UpdateMemberProfileBySupportDto {
	/** @format int32 */
	id?: number;

	/** @format int32 */
	tomedoId?: number | null;
	firstName: string;
	lastName: string;

	/** @format email */
	emailAddress: string;

	/** @format date-time */
	dateOfBirth: string;
	phoneNumber: string;
	street: string;
	city: string;
	zipCode: string;
	country: string;
	nationality: string;
	preferredLanguage: PreferredLanguage;

	/** @format uuid */
	clinicId?: string | null;
}

export interface UpdateMemberProfileDto {
	/** @format int32 */
	id?: number;
	firstName: string;
	lastName: string;

	/** @format date-time */
	dateOfBirth: string;
	phoneNumber: string;
	street: string;
	city: string;
	zipCode: string;
	country: string;
	nationality: string;
	preferredLanguage: PreferredLanguage;
}

export interface UpdateMemberProfileDuringAccountCreateDto {
	/** @format int32 */
	id?: number;
	firstName: string;
	lastName: string;

	/** @format date-time */
	dateOfBirth: string;
	phoneNumber: string;
	street: string;
	city: string;
	zipCode: string;
	country: string;
	nationality: string;
	preferredLanguage: PreferredLanguage;
	dataProtectionConfirmation: boolean;
	generalTermsInformation: boolean;
	newsletterConfirmation: boolean;
}

export interface UserDetails {
	member?: MemberDetailsSupportDto;
	reservations?: ReservationSupportDto[] | null;
}

export enum UserRoles {
	Admin = 'Admin',
	Support = 'Support',
	Doctor = 'Doctor',
	Patient = 'Patient',
}

export namespace AccountModule {
	/**
	 * No description
	 * @tags Account
	 * @name AccountSignupList
	 * @request GET:/account-module/Account/Signup
	 */
	export namespace AccountSignupList {
		export type RequestParams = {};
		export type RequestQuery = { redirectUrl?: string; lang?: string };
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags Account
	 * @name AccountSignInList
	 * @request GET:/account-module/Account/SignIn
	 */
	export namespace AccountSignInList {
		export type RequestParams = {};
		export type RequestQuery = { redirectUrl?: string; lang?: string };
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags Account
	 * @name AccountSignOutList
	 * @request GET:/account-module/Account/SignOut
	 */
	export namespace AccountSignOutList {
		export type RequestParams = {};
		export type RequestQuery = { redirectUrl?: string };
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags Account
	 * @name AccountGetClaimsList
	 * @request GET:/account-module/Account/GetClaims
	 */
	export namespace AccountGetClaimsList {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags Home
	 * @name AccountModuleList
	 * @request GET:/account-module
	 */
	export namespace AccountModuleList {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = string;
	}
	/**
	 * No description
	 * @tags SupportAccount
	 * @name SupportAccountCreateSupportMemberCreate
	 * @request POST:/account-module/SupportAccount/CreateSupportMember
	 */
	export namespace SupportAccountCreateSupportMemberCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = SupportUserDetails;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags SupportAccount
	 * @name SupportAccountResetPasswordList
	 * @request GET:/account-module/SupportAccount/ResetPassword
	 */
	export namespace SupportAccountResetPasswordList {
		export type RequestParams = {};
		export type RequestQuery = { redirectUrl?: string; lang?: string };
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags SupportAccount
	 * @name SupportAccountSignInList
	 * @request GET:/account-module/SupportAccount/SignIn
	 */
	export namespace SupportAccountSignInList {
		export type RequestParams = {};
		export type RequestQuery = { redirectUrl?: string; lang?: string };
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags SupportAccount
	 * @name SupportAccountSignOutList
	 * @request GET:/account-module/SupportAccount/SignOut
	 */
	export namespace SupportAccountSignOutList {
		export type RequestParams = {};
		export type RequestQuery = { redirectUrl?: string };
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
}

export namespace BookingModule {
	/**
	 * No description
	 * @tags Booking
	 * @name BookingAddReservationCreate
	 * @request POST:/booking-module/Booking/AddReservation
	 */
	export namespace BookingAddReservationCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = CreateReservationDto;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags Booking
	 * @name BookingUpdateReservationUpdate
	 * @request PUT:/booking-module/Booking/UpdateReservation/{id}
	 */
	export namespace BookingUpdateReservationUpdate {
		export type RequestParams = { id: string };
		export type RequestQuery = {};
		export type RequestBody = CreateReservationDto;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags Booking
	 * @name BookingDeleteReservationDelete
	 * @request DELETE:/booking-module/Booking/DeleteReservation/{id}
	 */
	export namespace BookingDeleteReservationDelete {
		export type RequestParams = { id: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags Booking
	 * @name BookingGetAvailableDatesList
	 * @request GET:/booking-module/Booking/GetAvailableDates
	 */
	export namespace BookingGetAvailableDatesList {
		export type RequestParams = {};
		export type RequestQuery = {
			DoctorId: string;
			ClinicId: string;
			MemberId?: number;
			ReservationType: ReservationType;
			IsOnline?: boolean;
			StartDay?: string;
			EndDay?: string;
			IgnoredReservationId?: string;
		};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = DoctorAvailableSlot[];
	}
	/**
	 * No description
	 * @tags Booking
	 * @name BookingGetReservationsList
	 * @request GET:/booking-module/Booking/GetReservations
	 */
	export namespace BookingGetReservationsList {
		export type RequestParams = {};
		export type RequestQuery = { reservationRange?: ReservationRange };
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = ReservationDto[];
	}
	/**
	 * No description
	 * @tags Booking
	 * @name BookingGetReservationTypesList
	 * @request GET:/booking-module/Booking/GetReservationTypes
	 */
	export namespace BookingGetReservationTypesList {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = ReservationTypeDto[];
	}
	/**
	 * No description
	 * @tags Booking
	 * @name BookingGetICalFileDetail
	 * @request GET:/booking-module/Booking/GetICalFile/{id}
	 */
	export namespace BookingGetICalFileDetail {
		export type RequestParams = { id: string };
		export type RequestQuery = { language?: string };
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags Booking
	 * @name BookingClinicsList
	 * @request GET:/booking-module/Booking/Clinics
	 */
	export namespace BookingClinicsList {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = ClinicCalendarListModel[];
	}
	/**
	 * No description
	 * @tags Home
	 * @name BookingModuleList
	 * @request GET:/booking-module
	 */
	export namespace BookingModuleList {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = string;
	}
	/**
	 * No description
	 * @tags SupportBooking
	 * @name SupportBookingGetAllClinicsList
	 * @request GET:/booking-module/SupportBooking/GetAllClinics
	 */
	export namespace SupportBookingGetAllClinicsList {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = ClinicDto[];
	}
	/**
	 * No description
	 * @tags SupportBooking
	 * @name SupportBookingClinicsAndDoctorsForTransferList
	 * @request GET:/booking-module/SupportBooking/ClinicsAndDoctorsForTransfer
	 */
	export namespace SupportBookingClinicsAndDoctorsForTransferList {
		export type RequestParams = {};
		export type RequestQuery = { reservationId?: string };
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = ClinicCalendarListModel[];
	}
	/**
	 * No description
	 * @tags SupportBooking
	 * @name SupportBookingAddClinicCreate
	 * @request POST:/booking-module/SupportBooking/AddClinic
	 */
	export namespace SupportBookingAddClinicCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = CreateClinicDto;
		export type RequestHeaders = {};
		export type ResponseBody = string;
	}
	/**
	 * No description
	 * @tags SupportBooking
	 * @name SupportBookingUpdateClinicUpdate
	 * @request PUT:/booking-module/SupportBooking/UpdateClinic/{id}
	 */
	export namespace SupportBookingUpdateClinicUpdate {
		export type RequestParams = { id: string };
		export type RequestQuery = {};
		export type RequestBody = CreateClinicDto;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags SupportBooking
	 * @name SupportBookingGetClinicDetail
	 * @request GET:/booking-module/SupportBooking/GetClinic/{id}
	 */
	export namespace SupportBookingGetClinicDetail {
		export type RequestParams = { id: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = ClinicDto;
	}
	/**
	 * No description
	 * @tags SupportBooking
	 * @name SupportBookingDeleteClinicDelete
	 * @request DELETE:/booking-module/SupportBooking/DeleteClinic/{id}
	 */
	export namespace SupportBookingDeleteClinicDelete {
		export type RequestParams = { id: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags SupportBooking
	 * @name SupportBookingAddDoctorCreate
	 * @request POST:/booking-module/SupportBooking/AddDoctor
	 */
	export namespace SupportBookingAddDoctorCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = CreateDoctorDto;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags SupportBooking
	 * @name SupportBookingUpdateDoctorUpdate
	 * @request PUT:/booking-module/SupportBooking/UpdateDoctor/{id}
	 */
	export namespace SupportBookingUpdateDoctorUpdate {
		export type RequestParams = { id: string };
		export type RequestQuery = {};
		export type RequestBody = CreateDoctorDto;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags SupportBooking
	 * @name SupportBookingGetAllDoctorsList
	 * @request GET:/booking-module/SupportBooking/GetAllDoctors
	 */
	export namespace SupportBookingGetAllDoctorsList {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = DoctorDto[];
	}
	/**
	 * No description
	 * @tags SupportBooking
	 * @name SupportBookingGetDoctorDetail
	 * @request GET:/booking-module/SupportBooking/GetDoctor/{id}
	 */
	export namespace SupportBookingGetDoctorDetail {
		export type RequestParams = { id: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = DoctorDto;
	}
	/**
	 * No description
	 * @tags SupportBooking
	 * @name SupportBookingDeleteDoctorDelete
	 * @request DELETE:/booking-module/SupportBooking/DeleteDoctor/{id}
	 */
	export namespace SupportBookingDeleteDoctorDelete {
		export type RequestParams = { id: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags SupportBooking
	 * @name SupportBookingAddDoctorAvailabilityCreate
	 * @request POST:/booking-module/SupportBooking/AddDoctorAvailability
	 */
	export namespace SupportBookingAddDoctorAvailabilityCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = CreateDoctorAvailabilityDto;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags SupportBooking
	 * @name SupportBookingDeleteDoctorAvailabilityDelete
	 * @request DELETE:/booking-module/SupportBooking/DeleteDoctorAvailability/{id}
	 */
	export namespace SupportBookingDeleteDoctorAvailabilityDelete {
		export type RequestParams = { id: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags SupportBooking
	 * @name SupportBookingUpdateDoctorAvailabilityUpdate
	 * @request PUT:/booking-module/SupportBooking/UpdateDoctorAvailability/{id}
	 */
	export namespace SupportBookingUpdateDoctorAvailabilityUpdate {
		export type RequestParams = { id: string };
		export type RequestQuery = {};
		export type RequestBody = UpdateDoctorAvailability;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags SupportBooking
	 * @name SupportBookingGetReservationTypesList
	 * @request GET:/booking-module/SupportBooking/GetReservationTypes
	 */
	export namespace SupportBookingGetReservationTypesList {
		export type RequestParams = {};
		export type RequestQuery = { patientId: number };
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = ReservationTypeDto[];
	}
}

export namespace HealthCheckInModule {
	/**
	 * No description
	 * @tags HealthCheckIn
	 * @name HealthCheckInAddCheckInCreate
	 * @request POST:/healthCheckIn-module/HealthCheckIn/AddCheckIn
	 */
	export namespace HealthCheckInAddCheckInCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = CreateCheckInDto;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags HealthCheckIn
	 * @name HealthCheckInGetLoggedMemberCheckInsList
	 * @request GET:/healthCheckIn-module/HealthCheckIn/GetLoggedMemberCheckIns
	 */
	export namespace HealthCheckInGetLoggedMemberCheckInsList {
		export type RequestParams = {};
		export type RequestQuery = { StartDate?: string; EndDate?: string };
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = CheckInDto[];
	}
	/**
	 * No description
	 * @tags HealthCheckIn
	 * @name HealthCheckInGetMemberCheckInsList
	 * @request GET:/healthCheckIn-module/HealthCheckIn/GetMemberCheckIns
	 */
	export namespace HealthCheckInGetMemberCheckInsList {
		export type RequestParams = {};
		export type RequestQuery = { UserId: number; StartDate?: string; EndDate?: string };
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = CheckInDto[];
	}
	/**
	 * No description
	 * @tags HealthCheckIn
	 * @name HealthCheckInCanAddCheckInForThisWeekList
	 * @request GET:/healthCheckIn-module/HealthCheckIn/CanAddCheckInForThisWeek
	 */
	export namespace HealthCheckInCanAddCheckInForThisWeekList {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = boolean;
	}
	/**
	 * No description
	 * @tags HealthCheckIn
	 * @name HealthCheckInExportExcelList
	 * @request GET:/healthCheckIn-module/HealthCheckIn/ExportExcel
	 */
	export namespace HealthCheckInExportExcelList {
		export type RequestParams = {};
		export type RequestQuery = {
			UserId: number;
			StartDate?: string;
			EndDate?: string;
			language?: PreferredLanguage;
		};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = boolean;
	}
	/**
	 * No description
	 * @tags Home
	 * @name HealthCheckInModuleList
	 * @request GET:/healthCheckIn-module
	 */
	export namespace HealthCheckInModuleList {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = string;
	}
}

export namespace MemberModule {
	/**
	 * No description
	 * @tags Home
	 * @name MemberModuleList
	 * @request GET:/member-module
	 */
	export namespace MemberModuleList {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = string;
	}
	/**
	 * No description
	 * @tags Member
	 * @name MemberGetPatientDetail
	 * @request GET:/member-module/Member/GetPatient/{id}
	 */
	export namespace MemberGetPatientDetail {
		export type RequestParams = { id: number };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = MemberProfileDto;
	}
	/**
	 * No description
	 * @tags Member
	 * @name MemberGetLoggedPatientList
	 * @request GET:/member-module/Member/GetLoggedPatient
	 */
	export namespace MemberGetLoggedPatientList {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = MemberProfileDto;
	}
	/**
	 * No description
	 * @tags Member
	 * @name MemberGetPatientsList
	 * @request GET:/member-module/Member/GetPatients
	 */
	export namespace MemberGetPatientsList {
		export type RequestParams = {};
		export type RequestQuery = {
			PageNumber?: number;
			PageSize?: number;
			$orderby?: string;
			$filter?: string;
		};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = MemberSupportDtoPagedResponse;
	}
	/**
	 * No description
	 * @tags Member
	 * @name MemberUpdate
	 * @request PUT:/member-module/Member/{id}
	 */
	export namespace MemberUpdate {
		export type RequestParams = { id: number };
		export type RequestQuery = {};
		export type RequestBody = UpdateMemberProfileBySupportDto;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags Member
	 * @name MemberUpdate2
	 * @request PUT:/member-module/Member
	 * @originalName memberUpdate
	 * @duplicate
	 */
	export namespace MemberUpdate2 {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = UpdateMemberProfileDto;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags Member
	 * @name MemberUpdateMemberDuringCreateAccountUpdate
	 * @request PUT:/member-module/Member/UpdateMemberDuringCreateAccount
	 */
	export namespace MemberUpdateMemberDuringCreateAccountUpdate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = UpdateMemberProfileDuringAccountCreateDto;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags Member
	 * @name MemberUploadPatientFilesCreate
	 * @request POST:/member-module/Member/UploadPatientFiles
	 */
	export namespace MemberUploadPatientFilesCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = { Files?: File[] };
		export type RequestHeaders = {};
		export type ResponseBody = string[];
	}
	/**
	 * No description
	 * @tags Member
	 * @name MemberUploadMedicalHistoryAndClinicCreate
	 * @request POST:/member-module/Member/UploadMedicalHistoryAndClinic
	 */
	export namespace MemberUploadMedicalHistoryAndClinicCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = MedicalHistoryDto;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags Member
	 * @name MemberChangeCheckinFlagCreate
	 * @request POST:/member-module/Member/ChangeCheckinFlag/{id}
	 */
	export namespace MemberChangeCheckinFlagCreate {
		export type RequestParams = { id: number };
		export type RequestQuery = { value?: boolean };
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags Member
	 * @name MemberClinicsList
	 * @request GET:/member-module/Member/Clinics
	 */
	export namespace MemberClinicsList {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = ClinicKeyValueDto[];
	}
	/**
	 * No description
	 * @tags Member
	 * @name MemberDocumentsList
	 * @request GET:/member-module/Member/Documents
	 */
	export namespace MemberDocumentsList {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = DocumentDto[];
	}
	/**
	 * No description
	 * @tags Member
	 * @name MemberDocumentsCreate
	 * @request POST:/member-module/Member/Documents
	 */
	export namespace MemberDocumentsCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = { Files?: File[] };
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags Member
	 * @name MemberDocumentsDetail
	 * @request GET:/member-module/Member/Documents/{id}
	 */
	export namespace MemberDocumentsDetail {
		export type RequestParams = { id: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags Member
	 * @name MemberDocumentsDelete
	 * @request DELETE:/member-module/Member/Documents/{id}
	 */
	export namespace MemberDocumentsDelete {
		export type RequestParams = { id: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags SaveForLater
	 * @name SaveForLaterCreate
	 * @request POST:/member-module/SaveForLater
	 */
	export namespace SaveForLaterCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = CreateSaveForLater;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags SaveForLater
	 * @name SaveForLaterList
	 * @request GET:/member-module/SaveForLater
	 */
	export namespace SaveForLaterList {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = SaveForLaterDto;
	}
	/**
	 * No description
	 * @tags SaveForLater
	 * @name SaveForLaterDelete
	 * @request DELETE:/member-module/SaveForLater
	 */
	export namespace SaveForLaterDelete {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
}

export namespace NotificationsModule {
	/**
	 * No description
	 * @tags Home
	 * @name NotificationsModuleList
	 * @request GET:/notifications-module
	 */
	export namespace NotificationsModuleList {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = string;
	}
}

export namespace SupportModule {
	/**
	 * No description
	 * @tags Home
	 * @name SupportModuleList
	 * @request GET:/support-module
	 */
	export namespace SupportModuleList {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = string;
	}
	/**
	 * No description
	 * @tags Support
	 * @name SupportGetUserDetailsDetail
	 * @request GET:/support-module/Support/GetUserDetails/{id}
	 */
	export namespace SupportGetUserDetailsDetail {
		export type RequestParams = { id: number };
		export type RequestQuery = { reservationRange?: ReservationRange };
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = UserDetails;
	}
	/**
	 * No description
	 * @tags Support
	 * @name SupportGetAllowedReservationTypesDetail
	 * @request GET:/support-module/Support/GetAllowedReservationTypes/{id}
	 */
	export namespace SupportGetAllowedReservationTypesDetail {
		export type RequestParams = { id: number };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = AllowedReservationTypesDto;
	}
	/**
	 * No description
	 * @tags Support
	 * @name SupportUpdateAllowedReservationTypesUpdate
	 * @request PUT:/support-module/Support/UpdateAllowedReservationTypes/{id}
	 */
	export namespace SupportUpdateAllowedReservationTypesUpdate {
		export type RequestParams = { id: number };
		export type RequestQuery = {};
		export type RequestBody = MemberDetailsSupportDto;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags Support
	 * @name SupportMembersCommentsDetail
	 * @request GET:/support-module/Support/Members/{memberId}/Comments
	 */
	export namespace SupportMembersCommentsDetail {
		export type RequestParams = { memberId: number };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = MemberCommentDto[];
	}
	/**
	 * No description
	 * @tags Support
	 * @name SupportMembersCommentsCreate
	 * @request POST:/support-module/Support/Members/{memberId}/Comments
	 */
	export namespace SupportMembersCommentsCreate {
		export type RequestParams = { memberId: number };
		export type RequestQuery = {};
		export type RequestBody = AddCommentDto;
		export type RequestHeaders = {};
		export type ResponseBody = MemberCommentDto;
	}
	/**
	 * No description
	 * @tags Support
	 * @name SupportValidatePatientCreate
	 * @request POST:/support-module/Support/ValidatePatient/{id}
	 */
	export namespace SupportValidatePatientCreate {
		export type RequestParams = { id: number };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags Support
	 * @name SupportDisapprovePatientCreate
	 * @request POST:/support-module/Support/DisapprovePatient/{id}
	 */
	export namespace SupportDisapprovePatientCreate {
		export type RequestParams = { id: number };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags Support
	 * @name SupportChangePatientStatusCreate
	 * @request POST:/support-module/Support/ChangePatientStatus/{id}
	 */
	export namespace SupportChangePatientStatusCreate {
		export type RequestParams = { id: number };
		export type RequestQuery = { finalStatus?: StatusType };
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags Support
	 * @name SupportChangePatientClinicCreate
	 * @request POST:/support-module/Support/ChangePatientClinic/{id}
	 */
	export namespace SupportChangePatientClinicCreate {
		export type RequestParams = { id: number };
		export type RequestQuery = { clinicId?: string };
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags Support
	 * @name SupportAddReservationCreate
	 * @request POST:/support-module/Support/AddReservation
	 */
	export namespace SupportAddReservationCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = CreateSupportReservationDto;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags Support
	 * @name SupportUpdateReservationUpdate
	 * @request PUT:/support-module/Support/UpdateReservation/{id}
	 */
	export namespace SupportUpdateReservationUpdate {
		export type RequestParams = { id: string };
		export type RequestQuery = {};
		export type RequestBody = CreateSupportReservationDto;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags Support
	 * @name SupportTransferReservationUpdate
	 * @request PUT:/support-module/Support/TransferReservation/{id}
	 */
	export namespace SupportTransferReservationUpdate {
		export type RequestParams = { id: string };
		export type RequestQuery = {};
		export type RequestBody = TransferReservationDto;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags Support
	 * @name SupportDeleteReservationDelete
	 * @request DELETE:/support-module/Support/DeleteReservation/{id}
	 */
	export namespace SupportDeleteReservationDelete {
		export type RequestParams = { id: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags Support
	 * @name SupportGetDoctorAvailabilityList
	 * @request GET:/support-module/Support/GetDoctorAvailability
	 */
	export namespace SupportGetDoctorAvailabilityList {
		export type RequestParams = {};
		export type RequestQuery = { DoctorId: string; ClinicId: string; StartDay?: string; EndDay?: string };
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = DoctorSupportAvailabilityDto[];
	}
	/**
	 * No description
	 * @tags Support
	 * @name SupportGetReservationsList
	 * @request GET:/support-module/Support/GetReservations
	 */
	export namespace SupportGetReservationsList {
		export type RequestParams = {};
		export type RequestQuery = { DoctorId: string; ClinicId: string; StartDay?: string; EndDay?: string };
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = ReservationSupportModel[];
	}
	/**
	 * No description
	 * @tags Support
	 * @name SupportGetReservationDetail
	 * @request GET:/support-module/Support/GetReservation/{id}
	 */
	export namespace SupportGetReservationDetail {
		export type RequestParams = { id: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = ReservationDetailsDto;
	}
	/**
	 * No description
	 * @tags Support
	 * @name SupportGetClinicsCalendarListList
	 * @request GET:/support-module/Support/GetClinicsCalendarList
	 * @deprecated
	 */
	export namespace SupportGetClinicsCalendarListList {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = ClinicCalendarListModel[];
	}
	/**
	 * No description
	 * @tags Support
	 * @name SupportClinicsList
	 * @request GET:/support-module/Support/Clinics
	 */
	export namespace SupportClinicsList {
		export type RequestParams = {};
		export type RequestQuery = { asKeyValuePair?: boolean };
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags Support
	 * @name SupportGetAppointmentsList
	 * @request GET:/support-module/Support/GetAppointments
	 */
	export namespace SupportGetAppointmentsList {
		export type RequestParams = {};
		export type RequestQuery = { DoctorId?: string; ClinicId: string; Date?: string };
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = AppointmentDto[];
	}
	/**
	 * No description
	 * @tags Support
	 * @name SupportUpdateAppointmentUpdate
	 * @request PUT:/support-module/Support/UpdateAppointment/{id}
	 */
	export namespace SupportUpdateAppointmentUpdate {
		export type RequestParams = { id: string };
		export type RequestQuery = {};
		export type RequestBody = AppointmentDto;
		export type RequestHeaders = {};
		export type ResponseBody = AppointmentDto[];
	}
	/**
	 * No description
	 * @tags Support
	 * @name SupportExportAppointmentsList
	 * @request GET:/support-module/Support/ExportAppointments
	 */
	export namespace SupportExportAppointmentsList {
		export type RequestParams = {};
		export type RequestQuery = {
			DoctorId?: string;
			ClinicId: string;
			From: string;
			To?: string;
			language?: string;
		};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags Support
	 * @name SupportAppointmentsCommentsDetail
	 * @request GET:/support-module/Support/Appointments/{reservationId}/Comments
	 */
	export namespace SupportAppointmentsCommentsDetail {
		export type RequestParams = { reservationId: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = MemberCommentDto[];
	}
	/**
	 * No description
	 * @tags Support
	 * @name SupportAppointmentsCommentsCreate
	 * @request POST:/support-module/Support/Appointments/{reservationId}/Comments
	 */
	export namespace SupportAppointmentsCommentsCreate {
		export type RequestParams = { reservationId: string };
		export type RequestQuery = {};
		export type RequestBody = AddCommentDto;
		export type RequestHeaders = {};
		export type ResponseBody = MemberCommentDto;
	}
	/**
	 * No description
	 * @tags Support
	 * @name SupportExportPatientsList
	 * @request GET:/support-module/Support/ExportPatients
	 */
	export namespace SupportExportPatientsList {
		export type RequestParams = {};
		export type RequestQuery = { From: string; To?: string; language?: string };
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags Support
	 * @name SupportPatientDocumentsDetail
	 * @request GET:/support-module/Support/Patient/{patientId}/Documents
	 */
	export namespace SupportPatientDocumentsDetail {
		export type RequestParams = { patientId: number };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = DocumentDto[];
	}
	/**
	 * No description
	 * @tags Support
	 * @name SupportPatientDocumentsCreate
	 * @request POST:/support-module/Support/Patient/{patientId}/Documents
	 */
	export namespace SupportPatientDocumentsCreate {
		export type RequestParams = { patientId: number };
		export type RequestQuery = {};
		export type RequestBody = { Files?: File[] };
		export type RequestHeaders = {};
		export type ResponseBody = DocumentListEntry[];
	}
	/**
	 * No description
	 * @tags Support
	 * @name SupportPatientDocumentsDetail2
	 * @request GET:/support-module/Support/Patient/{patientId}/Documents/{documentId}
	 * @originalName supportPatientDocumentsDetail
	 * @duplicate
	 */
	export namespace SupportPatientDocumentsDetail2 {
		export type RequestParams = { patientId: number; documentId: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags Support
	 * @name SupportPatientDocumentsDelete
	 * @request DELETE:/support-module/Support/Patient/{patientId}/Documents/{documentId}
	 */
	export namespace SupportPatientDocumentsDelete {
		export type RequestParams = { patientId: number; documentId: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags Support
	 * @name SupportPatientDocumentsReadUpdate
	 * @request PUT:/support-module/Support/Patient/{patientId}/Documents/{documentId}/Read
	 */
	export namespace SupportPatientDocumentsReadUpdate {
		export type RequestParams = { patientId: number; documentId: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = DocumentDto;
	}
	/**
	 * No description
	 * @tags Support
	 * @name SupportPatientDocumentsUnreadUpdate
	 * @request PUT:/support-module/Support/Patient/{patientId}/Documents/{documentId}/Unread
	 */
	export namespace SupportPatientDocumentsUnreadUpdate {
		export type RequestParams = { patientId: number; documentId: string };
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = DocumentDto;
	}
	/**
	 * No description
	 * @tags Support
	 * @name SupportPatientDocumentsDisplayNameUpdate
	 * @request PUT:/support-module/Support/Patient/{patientId}/Documents/{documentId}/DisplayName
	 */
	export namespace SupportPatientDocumentsDisplayNameUpdate {
		export type RequestParams = { patientId: number; documentId: string };
		export type RequestQuery = {};
		export type RequestBody = UpdateDocumentDisplayNameDto;
		export type RequestHeaders = {};
		export type ResponseBody = DocumentDto;
	}
}

import { ReservationRange, ReservationType, StatusType } from 'apps/web/lib/types';

if (!process.env.BACKEND_BASE_URL) {
	// eslint-disable-next-line no-console
	console.error('Missing BACKEND_BASE_URL env var');
}

export const URLS = {
	backend: process.env.BACKEND_BASE_URL || process.env.STORYBOOK_BACKEND_BASE_URL,
	frontend:
		process.env.FRONTEND_BASE_URL || typeof window !== 'undefined' ? window.location.origin : undefined,
};

export const API_ROUTES = {
	SUPPORT_TODAYS_LIST_DOWNLOAD: (
		clinicId: string,
		from: string,
		doctorId?: string,
		to?: string,
		locale?: string,
	): string => {
		let url = `${URLS.backend}/support-module/Support/ExportAppointments?clinicId=${clinicId}&from=${from}`;
		if (doctorId) {
			url += `&doctorId=${doctorId}`;
		}

		if (to) {
			url += `&to=${to}`;
		}

		if (locale) {
			url += `&language=${locale}`;
		}

		return url;
	},
	SUPPORT_PATIENT_LIST_DOWNLOAD: (from: string, to?: string, locale?: string): string => {
		let url = `${URLS.backend}/support-module/Support/ExportPatients?from=${from}`;

		if (to) {
			url += `&to=${to}`;
		}

		if (locale) {
			url += `&language=${locale}`;
		}

		return url;
	},
	SUPPORT_ICAL_DOWNLOAD: (reservationId: string, language: string): string =>
		`${URLS.backend}/booking-module/Booking/GetICalFile/${reservationId}?language=${language}`,
	SUPPORT_GETUSERDETAILS: (userId: number, reservationRange: ReservationRange): string =>
		`${URLS.backend}/support-module/Support/GetUserDetails/${userId}?reservationRange=${reservationRange}`,
	SUPPORT_VALIDATEPATION: `${URLS.backend}/support-module/Support/ValidatePatient`,
	SUPPORT_ADD_CLINIC: `${URLS.backend}/booking-module/SupportBooking/AddClinic`,
	SUPPORT_UPDATE_CLINIC: (clinicId: string): string =>
		`${URLS.backend}/booking-module/SupportBooking/UpdateClinic/${clinicId}`,
	SUPPORT_DELETE_CLINIC: (clinicId: string): string =>
		`${URLS.backend}/booking-module/SupportBooking/DeleteClinic/${clinicId}`,
	SUPPORT_GET_CLINICS_CALENDAR_LIST: `${URLS.backend}/support-module/Support/GetClinicsCalendarList`,
	SUPPORT_GET_DOCTOR_AVAILABILITY: `${URLS.backend}/support-module/Support/GetDoctorAvailability`,
	SUPPORT_GET_RESERVATIONS: `${URLS.backend}/support-module/Support/GetReservations`,
	SUPPORT_GET_RESERVATION: (reservationId: string): string =>
		`${URLS.backend}/support-module/Support/GetReservation/${reservationId}`,
	SUPPORT_PATIENT_COMMENTS: (userId: number): string =>
		`${URLS.backend}/support-module/Support/Members/${userId}/Comments`,
	SUPPORT_ADD_DOCTOR_AVAILABILITY: `${URLS.backend}/booking-module/SupportBooking/AddDoctorAvailability`,
	SUPPORT_UPDATE_DOCTOR_AVAILABILITY: (id: string) =>
		`${URLS.backend}/booking-module/SupportBooking/UpdateDoctorAvailability/${id}`,
	SUPPORT_DELETE_DOCTOR_AVAILABILITY: (id: string) =>
		`${URLS.backend}/booking-module/SupportBooking/DeleteDoctorAvailability/${id}`,
	SUPPORT_GETCLINICS: `${URLS.backend}/support-module/Support/Clinics`,
	SUPPORT_GETCLINICS_FOR_TRANSFER: (reservationId: string) =>
		`${URLS.backend}/booking-module/SupportBooking/ClinicsAndDoctorsForTransfer?reservationId=${reservationId}`,
	SUPPORT_CLINICS_KEY_VALUE: `${URLS.backend}/support-module/Support/Clinics?asKeyValuePair=true`,
	SUPPORT_ADD_DOCTOR: `${URLS.backend}/booking-module/SupportBooking/AddDoctor`,
	SUPPORT_UPDATE_DOCTOR: (doctorId: string): string =>
		`${URLS.backend}/booking-module/SupportBooking/UpdateDoctor/${doctorId}`,
	SUPPORT_DELETE_DOCTOR: (doctorId: string): string =>
		`${URLS.backend}/booking-module/SupportBooking/DeleteDoctor/${doctorId}`,
	SUPPORT_VALIDATEPATIENT: (userId: number): string =>
		`${URLS.backend}/support-module/Support/ValidatePatient/${userId}`,
	SUPPORT_DISAPPROVEPATIENT: (userId: number): string =>
		`${URLS.backend}/support-module/Support/DisapprovePatient/${userId}`,
	SUPPORT_CHANGEPATIENTSTATUS: (userId: number, finalStatus: StatusType): string =>
		`${URLS.backend}/support-module/Support/ChangePatientStatus/${userId}?finalStatus=${finalStatus}`,
	SUPPORT_ADDRESERVATION: `${URLS.backend}/support-module/Support/AddReservation`,
	SUPPORT_DELETERESERVATION: (reservationId: string) =>
		`${URLS.backend}/support-module/Support/DeleteReservation/${reservationId}`,
	SUPPORT_UPDATE_RESERVATION: (reservationId: string) =>
		`${URLS.backend}/support-module/Support/UpdateReservation/${reservationId}`,
	SUPPORT_TRANSFER_RESERVATION: (reservationId: string) =>
		`${URLS.backend}/support-module/Support/TransferReservation/${reservationId}`,
	SUPPORT_GET_APPOINTMENTS: (clinicId: string, doctorId: string, date: string) => {
		const qs = new URLSearchParams(
			[
				['clinicId', clinicId as string],
				['doctorId', doctorId as string],
				['date', date as string],
			].filter(([, value]) => typeof value === 'string'),
		);

		return `${URLS.backend}/support-module/Support/GetAppointments?${qs}`;
	},
	SUPPORT_APPOINTMENT_COMMENTS: (reservationId: string): string =>
		`${URLS.backend}/support-module/Support/Appointments/${reservationId}/Comments`,
	SUPPORT_UPDATE_APPOINTMENT: (reservationId: string) =>
		`${URLS.backend}/support-module/Support/UpdateAppointment/${reservationId}`,
	SUPPORT_GET_ALLOWED_RESERVATION_TYPES: (userId: number): string =>
		`${URLS.backend}/support-module/Support/GetAllowedReservationTypes/${userId}`,
	SUPPORT_UPDATE_ALLOWED_RESERVATION_TYPES: (memberId: number) =>
		`${URLS.backend}/support-module/Support/UpdateAllowedReservationTypes/${memberId}`,
	MEMBER_UPDATEMEMBER: `${URLS.backend}/member-module/Member`,
	MEMBER_UPDATEMEMBERDETAILS: (userId: number) => `${URLS.backend}/member-module/Member/${userId}`,
	MEMBER_UPDATEMEMBERDURINGCREATEACCOUNT: `${URLS.backend}/member-module/Member/UpdateMemberDuringCreateAccount`,
	MEMBER_UPDATEMEMBERDURINGCREATEACCOUNTDETAILS: (userId: number) =>
		`${URLS.backend}/member-module/Member/UpdateMemberDuringCreateAccount/${userId}`,
	BOOKING_GET_RESERVATION_TYPES: `${URLS.backend}/booking-module/Booking/GetReservationTypes`,
	BOOKING_GET_RESERVATION_TYPES_SUPPORT: (userId?: number): string | null =>
		(userId ? `${URLS.backend}/booking-module/SupportBooking/GetReservationTypes?patientId=${userId}` : null),
	BOOKING_GETCLINICS: `${URLS.backend}/booking-module/Booking/Clinics`,
	MEMBER_GETDOCUMENTS: `${URLS.backend}/member-module/Member/Documents`,
	MEMBER_GETDOCUMENT: (documentId: string) => `${URLS.backend}/member-module/Member/Documents/${documentId}`,
	MEMBER_UPLOAD_DOCUMENT: `${URLS.backend}/member-module/Member/Documents`,

	SUPPORT_GETDOCUMENTS: (userId?: number): string | null =>
		(userId ? `${URLS.backend}/support-module/Support/Patient/${userId}/Documents` : null),
	SUPPORT_GETDOCUMENT: (userId: number, documentId: string): string | null =>
		(documentId ? `${URLS.backend}/support-module/Support/Patient/${userId}/Documents/${documentId}` : null),
	SUPPORT_DELETE_DOCUMENT: (userId: number, documentId: string): string | null =>
		(documentId ? `${URLS.backend}/support-module/Support/Patient/${userId}/Documents/${documentId}` : null),
	SUPPORT_UPLOAD_DOCUMENT: (userId: number): string | null =>
		`${URLS.backend}/support-module/Support/Patient/${userId}/Documents`,
	SUPPORT_SET_DOCUMENT_READ: (userId: number, documentId: string): string | null =>
		(documentId ? `${URLS.backend}/support-module/Support/Patient/${userId}/Documents/${documentId}/Read` : null),
	SUPPORT_SET_DOCUMENT_UNREAD: (userId: number, documentId: string): string | null =>
		(documentId ? `${URLS.backend}/support-module/Support/Patient/${userId}/Documents/${documentId}/Unread` : null),
	SUPPORT_SET_DOCUMENT_DISPLAY_NAME: (userId: number, documentId: string): string | null =>
		(documentId ? `${URLS.backend}/support-module/Support/Patient/${userId}/Documents/${documentId}/DisplayName` : null),

	BOOKING_GETAVAILABLEDATES: (
		doctorId?: string,
		clinicId?: string,
		memberId?: number,
		reservationType?: ReservationType,
		currentReservationId?: string,
		startDay?: string,
		endDay?: string,
	) => {
		const qs = new URLSearchParams(
			[
				['doctorId', doctorId as string],
				['clinicId', clinicId as string],
				['memberId', memberId?.toString() as string],
				['reservationType', reservationType as string],
				['ignoredReservationId', currentReservationId as string],
				['startDay', startDay as string],
				['endDay', endDay as string],
			].filter(([, value]) => typeof value === 'string'),
		);

		return `${URLS.backend}/booking-module/Booking/GetAvailableDates?${qs}`;
	},
	BOOKING_ADDRESERVATION: `${URLS.backend}/booking-module/Booking/AddReservation`,
	BOOKING_UPDATE_RESERVATION: (reservationId: string) =>
		`${URLS.backend}/booking-module/Booking/UpdateReservation/${reservationId}`,
	BOOKING_GETRESERVATIONS: `${URLS.backend}/booking-module/Booking/GetReservations?reservationRange=${ReservationRange.All}`,
	BOOKING_DELETERESERVATION: (reservationId: string) =>
		`${URLS.backend}/booking-module/Booking/DeleteReservation/${reservationId}`,
	MEMBER_GETLOGGEDINPATIENT: `${URLS.backend}/member-module/Member/GetLoggedPatient`,
	MEMBER_GETPATIENTS: (querystring?: string): string =>
		`${URLS.backend}/member-module/Member/GetPatients${querystring ? ['?', querystring].join('') : ''}`,
	MEMBER_PUTMEMBER: `${URLS.backend}/member-module/Member`,
	HEALTH_ADDCHECKIN: `${URLS.backend}/healthCheckIn-module/HealthCheckIn/AddCheckIn`,
	HEALTH_CANADDCHECKINFORTHISWEEK: `${URLS.backend}/healthCheckIn-module/HealthCheckIn/CanAddCheckInForThisWeek`,
	HEALTH_GETMEMBERCHECKINS: (memberId?: number) =>
		`${URLS.backend}/healthCheckIn-module/HealthCheckIn/GetMemberCheckIns?UserId=${memberId}`,
	HEALTH_EXPORTEXCEL: (memberId: number, language: string, startDate?: string, endDate?: string) => {
		const startDateParam = startDate ? `&StarteDate=${startDate}` : '';
		const endDateParam = endDate ? `&EndDate=${endDate}` : '';
		const languageParam = language ? `&language=${language}` : '';

		return `${URLS.backend}/healthCheckIn-module/HealthCheckIn/ExportExcel?UserId=${memberId}${languageParam}${startDateParam}${endDateParam}`;
	},
	HEALTH_GETLOGGEDMEMBERCHEKCINS: `${URLS.backend}/healthCheckIn-module/HealthCheckIn/GetLoggedMemberCheckIns`,
	MEMBER_UPLOADMEDICALHISTORY: `${URLS.backend}/member-module/Member/UploadMedicalHistoryAndClinic`,
	MEMBER_UPLOADPATIENTFILES: `${URLS.backend}/member-module/Member/UploadPatientFiles`,
	MEMBER_SAVEFORLATERPOST: `${URLS.backend}/member-module/SaveForLater`,
	MEMBER_SAVEFORLATERGET: `${URLS.backend}/member-module/SaveForLater`,
	MEMBER_SAVEFORLATERDELETE: `${URLS.backend}/member-module/SaveForLater`,
	MEMBER_CHANGECHECKINFLAG: (memberId: number, value: boolean): string =>
		`${URLS.backend}/member-module/Member/ChangeCheckinFlag/${memberId}?value=${value}`,
	CLINICS_KEY_VALUE: (forOnboarding: boolean): string =>
		`${URLS.backend}/member-module/Member/Clinics?forOnboarding=${forOnboarding}`,
	ACCOUNT_SIGNIN: (redirectUrl: string, locale = 'en') =>
		`${URLS.backend}/account-module/Account/SignIn?redirectUrl=${encodeURIComponent(
			redirectUrl,
		)}&lang=${locale}`,
	ACCOUNT_SIGNOUT: (redirectUrl: string) =>
		`${URLS.backend}/account-module/Account/SignOut?redirectUrl=${encodeURIComponent(redirectUrl)}`,
	ACCOUNT_SIGNUP: (redirectUrl: string, locale = 'en') =>
		`${URLS.backend}/account-module/Account/SignUp?redirectUrl=${encodeURIComponent(
			redirectUrl,
		)}&lang=${locale}`,
	SUPPORT_SIGNIN: (redirectUrl: string, locale = 'en') =>
		`${URLS.backend}/account-module/SupportAccount/SignIn?redirectUrl=${encodeURIComponent(
			redirectUrl,
		)}&lang=${locale}`,
	SUPPORT_SIGNOUT: (redirectUrl: string) =>
		`${URLS.backend}/account-module/SupportAccount/SignOut?redirectUrl=${encodeURIComponent(redirectUrl)}`,
};

export const nationalities = [
	{ en: 'Afghan', value: 'Afghan', de: 'Afghanisch' },
	{ en: 'Albanian', value: 'Albanian', de: 'Albanisch' },
	{ en: 'Algerian', value: 'Algerian', de: 'Algerisch' },
	{ en: 'American', value: 'American', de: 'Amerikanisch' },
	{ en: 'Andorran', value: 'Andorran', de: 'Andorran' },
	{ en: 'Angolan', value: 'Angolan', de: 'Angolanisch' },
	{ en: 'Anguillan', value: 'Anguillan', de: 'Anguillan' },
	{ en: 'Argentine', value: 'Argentine', de: 'Argentinien' },
	{ en: 'Armenian', value: 'Armenian', de: 'Armenisch' },
	{ en: 'Australian', value: 'Australian', de: 'Australisch' },
	{ en: 'Austrian', value: 'Austrian', de: 'Österreichisch' },
	{ en: 'Azerbaijani', value: 'Azerbaijani', de: 'Aserbaidschanisch' },
	{ en: 'Bahamian', value: 'Bahamian', de: 'Bahamas' },
	{ en: 'Bahraini', value: 'Bahraini', de: 'Bahrain' },
	{ en: 'Bangladeshi', value: 'Bangladeshi', de: 'Bangladesch' },
	{ en: 'Barbadian', value: 'Barbadian', de: 'Barbadisch' },
	{ en: 'Belarusian', value: 'Belarusian', de: 'Belarussisch' },
	{ en: 'Belgian', value: 'Belgian', de: 'Belgier' },
	{ en: 'Belizean', value: 'Belizean', de: 'Belize' },
	{ en: 'Beninese', value: 'Beninese', de: 'Benin' },
	{ en: 'Bermudian', value: 'Bermudian', de: 'Bermuda' },
	{ en: 'Bhutanese', value: 'Bhutanese', de: 'Bhutan' },
	{ en: 'Bolivian', value: 'Bolivian', de: 'Bolivianisch' },
	{ en: 'Botswanan', value: 'Botswanan', de: 'Botswana' },
	{ en: 'Brazilian', value: 'Brazilian', de: 'Brasilianisch' },
	{ en: 'British', value: 'British', de: 'Britisch' },
	{
		en: 'British Virgin Islander',
		value: 'British Virgin Islander',
		de: 'Britische Jungferninseln',
	},
	{ en: 'Bruneian', value: 'Bruneian', de: 'Bruneisch' },
	{ en: 'Bulgarian', value: 'Bulgarian', de: 'Bulgarisch' },
	{ en: 'Burkinan', value: 'Burkinan', de: 'Burkinan' },
	{ en: 'Burmese', value: 'Burmese', de: 'Birmanisch' },
	{ en: 'Burundian', value: 'Burundian', de: 'Burundisch' },
	{ en: 'Cambodian', value: 'Cambodian', de: 'Kambodschanisch' },
	{ en: 'Cameroonian', value: 'Cameroonian', de: 'Kameruner' },
	{ en: 'Canadian', value: 'Canadian', de: 'Kanadisch' },
	{ en: 'Cape Verdean', value: 'Cape Verdean', de: 'Kapverdische Inseln' },
	{ en: 'Cayman Islander', value: 'Cayman Islander', de: 'Kaimaninseln' },
	{ en: 'Central African', value: 'Central African', de: 'Zentralafrika' },
	{ en: 'Chadian', value: 'Chadian', de: 'Tschadisch' },
	{ en: 'Chilean', value: 'Chilean', de: 'Chilenisch' },
	{ en: 'Chinese', value: 'Chinese', de: 'Chinesisch' },
	{
		en: 'Citizen of Antigua and Barbuda',
		value: 'Citizen of Antigua and Barbuda',
		de: 'Bürger von Antigua und Barbuda',
	},
	{
		en: 'Citizen of Bosnia and Herzegovina',
		value: 'Citizen of Bosnia and Herzegovina',
		de: 'Bürger von Bosnien und Herzegowina',
	},
	{
		en: 'Citizen of Guinea-Bissau',
		value: 'Citizen of Guinea-Bissau',
		de: 'Bürger von Guinea-Bissau',
	},
	{
		en: 'Citizen of Kiribati',
		value: 'Citizen of Kiribati',
		de: 'Bürger von Kiribati',
	},
	{
		en: 'Citizen of Seychelles',
		value: 'Citizen of Seychelles',
		de: 'Bürger der Seychellen',
	},
	{
		en: 'Citizen of the Dominican Republic',
		value: 'Citizen of the Dominican Republic',
		de: 'Bürger der Dominikanischen Republik',
	},
	{
		en: 'Citizen of Vanuatu ',
		value: 'Citizen of Vanuatu ',
		de: 'Bürger von Vanuatu',
	},
	{ en: 'Colombian', value: 'Colombian', de: 'Kolumbianisch' },
	{ en: 'Comoran', value: 'Comoran', de: 'Komoren' },
	{
		en: 'Congolese (Congo)',
		value: 'Congolese (Congo)',
		de: 'Kongolesen (Kongo)',
	},
	{ en: 'Congolese (DRC)', value: 'Congolese (DRC)', de: 'Kongolesen (DRC)' },
	{ en: 'Cook Islander', value: 'Cook Islander', de: 'Cook-Insulaner' },
	{ en: 'Costa Rican', value: 'Costa Rican', de: 'Costaricanisch' },
	{ en: 'Croatian', value: 'Croatian', de: 'Kroatisch' },
	{ en: 'Cuban', value: 'Cuban', de: 'Kubaner' },
	{ en: 'Cymraes', value: 'Cymraes', de: 'Cymraes' },
	{ en: 'Cymro', value: 'Cymro', de: 'Cymro' },
	{ en: 'Cypriot', value: 'Cypriot', de: 'Zypriotisch' },
	{ en: 'Czech', value: 'Czech', de: 'Tschechisch' },
	{ en: 'Danish', value: 'Danish', de: 'Dänisch' },
	{ en: 'Djiboutian', value: 'Djiboutian', de: 'Dschibutisch' },
	{ en: 'Dominican', value: 'Dominican', de: 'Dominikanisch' },
	{ en: 'Dutch', value: 'Dutch', de: 'Niederländisch' },
	{ en: 'East Timorese', value: 'East Timorese', de: 'Osttimorese' },
	{ en: 'Ecuadorean', value: 'Ecuadorean', de: 'Ecuadorianisch' },
	{ en: 'Egyptian', value: 'Egyptian', de: 'Ägyptisch' },
	{ en: 'Emirati', value: 'Emirati', de: 'Emirate' },
	{ en: 'English', value: 'English', de: 'Englisch' },
	{
		en: 'Equatorial Guinean',
		value: 'Equatorial Guinean',
		de: 'Äquatorialguineisch',
	},
	{ en: 'Eritrean', value: 'Eritrean', de: 'Eritreisch' },
	{ en: 'Estonian', value: 'Estonian', de: 'Estnisch' },
	{ en: 'Ethiopian', value: 'Ethiopian', de: 'Äthiopisch' },
	{ en: 'Faroese', value: 'Faroese', de: 'Färöisch' },
	{ en: 'Fijian', value: 'Fijian', de: 'Fidschian' },
	{ en: 'Filipino', value: 'Filipino', de: 'Philippinisch' },
	{ en: 'Finnish', value: 'Finnish', de: 'Finnisch' },
	{ en: 'French', value: 'French', de: 'Französisch' },
	{ en: 'Gabonese', value: 'Gabonese', de: 'Gabunisch' },
	{ en: 'Gambian', value: 'Gambian', de: 'Gambier' },
	{ en: 'Georgian', value: 'Georgian', de: 'Georgisch' },
	{ en: 'German', value: 'German', de: 'Deutsch' },
	{ en: 'Ghanaian', value: 'Ghanaian', de: 'Ghanaisch' },
	{ en: 'Gibraltarian', value: 'Gibraltarian', de: 'Gibraltarisch' },
	{ en: 'Greek', value: 'Greek', de: 'Griechisch' },
	{ en: 'Greenlandic', value: 'Greenlandic', de: 'Grönländisch' },
	{ en: 'Grenadian', value: 'Grenadian', de: 'Grenadisch' },
	{ en: 'Guamanian', value: 'Guamanian', de: 'Guamanisch' },
	{ en: 'Guatemalan', value: 'Guatemalan', de: 'Guatemaltekisch' },
	{ en: 'Guinean', value: 'Guinean', de: 'Guinean' },
	{ en: 'Guyanese', value: 'Guyanese', de: 'Guyanesisch' },
	{ en: 'Haitian', value: 'Haitian', de: 'Haitianisch' },
	{ en: 'Honduran', value: 'Honduran', de: 'Honduranisch' },
	{ en: 'Hong Konger', value: 'Hong Konger', de: 'Hongkonger' },
	{ en: 'Hungarian', value: 'Hungarian', de: 'Ungarisch' },
	{ en: 'Icelandic', value: 'Icelandic', de: 'Isländisch' },
	{ en: 'Indian', value: 'Indian', de: 'Indisch' },
	{ en: 'Indonesian', value: 'Indonesian', de: 'Indonesisch' },
	{ en: 'Iranian', value: 'Iranian', de: 'Iranisch' },
	{ en: 'Iraqi', value: 'Iraqi', de: 'Irakisch' },
	{ en: 'Irish', value: 'Irish', de: 'Irisch' },
	{ en: 'Israeli', value: 'Israeli', de: 'Israelisch' },
	{ en: 'Italian', value: 'Italian', de: 'Italienisch' },
	{ en: 'Ivorian', value: 'Ivorian', de: 'Elfenbeinküste' },
	{ en: 'Jamaican', value: 'Jamaican', de: 'Jamaikanisch' },
	{ en: 'Japanese', value: 'Japanese', de: 'Japanisch' },
	{ en: 'Jordanian', value: 'Jordanian', de: 'Jordanier' },
	{ en: 'Kazakh', value: 'Kazakh', de: 'Kasachisch' },
	{ en: 'Kenyan', value: 'Kenyan', de: 'Kenianisch' },
	{ en: 'Kittitian', value: 'Kittitian', de: 'Kittitian' },
	{ en: 'Kosovan', value: 'Kosovan', de: 'Kosovo' },
	{ en: 'Kuwaiti', value: 'Kuwaiti', de: 'Kuwait' },
	{ en: 'Kyrgyz', value: 'Kyrgyz', de: 'Kirgisistan' },
	{ en: 'Lao', value: 'Lao', de: 'Laos' },
	{ en: 'Latvian', value: 'Latvian', de: 'Lettisch' },
	{ en: 'Lebanese', value: 'Lebanese', de: 'Libanesisch' },
	{ en: 'Liberian', value: 'Liberian', de: 'Liberianisch' },
	{ en: 'Libyan', value: 'Libyan', de: 'Libysche' },
	{
		en: 'Liechtenstein citizen',
		value: 'Liechtenstein citizen',
		de: 'Liechtensteiner Staatsbürger',
	},
	{ en: 'Lithuanian', value: 'Lithuanian', de: 'Litauisch' },
	{ en: 'Luxembourger', value: 'Luxembourger', de: 'Luxemburger' },
	{ en: 'Macanese', value: 'Macanese', de: 'Macanesisch' },
	{ en: 'Macedonian', value: 'Macedonian', de: 'Mazedonisch' },
	{ en: 'Malagasy', value: 'Malagasy', de: 'Madagassisch' },
	{ en: 'Malawian', value: 'Malawian', de: 'Malawi' },
	{ en: 'Malaysian', value: 'Malaysian', de: 'Malaysisch' },
	{ en: 'Maldivian', value: 'Maldivian', de: 'Malediven' },
	{ en: 'Malian', value: 'Malian', de: 'Malisch' },
	{ en: 'Maltese', value: 'Maltese', de: 'Maltesisch' },
	{ en: 'Marshallese', value: 'Marshallese', de: 'Marshallesisch' },
	{ en: 'Martiniquais', value: 'Martiniquais', de: 'Martiniquais' },
	{ en: 'Mauritanian', value: 'Mauritanian', de: 'Mauretanien' },
	{ en: 'Mauritian', value: 'Mauritian', de: 'Mauritius' },
	{ en: 'Mexican', value: 'Mexican', de: 'Mexikaner' },
	{ en: 'Micronesian', value: 'Micronesian', de: 'Mikronesier' },
	{ en: 'Moldovan', value: 'Moldovan', de: 'Moldawien' },
	{ en: 'Monegasque', value: 'Monegasque', de: 'Monegassisch' },
	{ en: 'Mongolian', value: 'Mongolian', de: 'Mongolisch' },
	{ en: 'Montenegrin', value: 'Montenegrin', de: 'Montenegrinisch' },
	{ en: 'Montserratian', value: 'Montserratian', de: 'Montserratian' },
	{ en: 'Moroccan', value: 'Moroccan', de: 'Marokkanisch' },
	{ en: 'Mosotho', value: 'Mosotho', de: 'Mosotho' },
	{ en: 'Mozambican', value: 'Mozambican', de: 'Mosambik' },
	{ en: 'Namibian', value: 'Namibian', de: 'Namibisch' },
	{ en: 'Nauruan', value: 'Nauruan', de: 'Nauruisch' },
	{ en: 'Nepalese', value: 'Nepalese', de: 'Nepalese' },
	{ en: 'New Zealander', value: 'New Zealander', de: 'Neuseeländer' },
	{ en: 'Nicaraguan', value: 'Nicaraguan', de: 'Nicaraguan' },
	{ en: 'Nigerian', value: 'Nigerian', de: 'Nigerianisch' },
	{ en: 'Nigerien', value: 'Nigerien', de: 'Nigeria' },
	{ en: 'Niuean', value: 'Niuean', de: 'Niuean' },
	{ en: 'North Korean', value: 'North Korean', de: 'Nordkoreanisch' },
	{ en: 'Northern Irish', value: 'Northern Irish', de: 'Nordirisch' },
	{ en: 'Norwegian', value: 'Norwegian', de: 'Norwegisch' },
	{ en: 'Omani', value: 'Omani', de: 'Omanisch' },
	{ en: 'Pakistani', value: 'Pakistani', de: 'Pakistanisch' },
	{ en: 'Palauan', value: 'Palauan', de: 'Palauan' },
	{ en: 'Palestinian', value: 'Palestinian', de: 'Palästinensisch' },
	{ en: 'Panamanian', value: 'Panamanian', de: 'Panamaisch' },
	{
		en: 'Papua New Guinean',
		value: 'Papua New Guinean',
		de: 'Papua-Neuguinea',
	},
	{ en: 'Paraguayan', value: 'Paraguayan', de: 'Paraguay' },
	{ en: 'Peruvian', value: 'Peruvian', de: 'Peruanisch' },
	{
		en: 'Pitcairn Islander',
		value: 'Pitcairn Islander',
		de: 'Pitcairn-Insulaner',
	},
	{ en: 'Polish', value: 'Polish', de: 'Polieren' },
	{ en: 'Portuguese', value: 'Portuguese', de: 'Portugiesisch' },
	{ en: 'Prydeinig', value: 'Prydeinig', de: 'Prydeinig' },
	{ en: 'Puerto Rican', value: 'Puerto Rican', de: 'Puertorikanisch' },
	{ en: 'Qatari', value: 'Qatari', de: 'Katar' },
	{ en: 'Romanian', value: 'Romanian', de: 'Rumänisch' },
	{ en: 'Russian', value: 'Russian', de: 'Russisch' },
	{ en: 'Rwandan', value: 'Rwandan', de: 'Ruanda' },
	{ en: 'Salvadorean', value: 'Salvadorean', de: 'Salvadorianisch' },
	{ en: 'Sammarinese', value: 'Sammarinese', de: 'Sammarinesisch' },
	{ en: 'Samoan', value: 'Samoan', de: 'Samoanisch' },
	{ en: 'Sao Tomean', value: 'Sao Tomean', de: 'Sao Tomean' },
	{ en: 'Saudi Arabian', value: 'Saudi Arabian', de: 'Saudi-arabisch' },
	{ en: 'Scottish', value: 'Scottish', de: 'Schottisch' },
	{ en: 'Senegalese', value: 'Senegalese', de: 'Senegalesisch' },
	{ en: 'Serbian', value: 'Serbian', de: 'Serbisch' },
	{ en: 'Sierra Leonean', value: 'Sierra Leonean', de: 'Sierra Leone' },
	{ en: 'Singaporean', value: 'Singaporean', de: 'Singapurisch' },
	{ en: 'Slovak', value: 'Slovak', de: 'Slowakisch' },
	{ en: 'Slovenian', value: 'Slovenian', de: 'Slowenisch' },
	{
		en: 'Solomon Islander',
		value: 'Solomon Islander',
		de: 'Salomon-Insulaner',
	},
	{ en: 'Somali', value: 'Somali', de: 'Somali' },
	{ en: 'South African', value: 'South African', de: 'Südafrikanisch' },
	{ en: 'South Korean', value: 'South Korean', de: 'Südkorea' },
	{ en: 'South Sudanese', value: 'South Sudanese', de: 'Südsudanesen' },
	{ en: 'Spanish', value: 'Spanish', de: 'Spanisch' },
	{ en: 'Sri Lankan', value: 'Sri Lankan', de: 'Sri Lanka' },
	{ en: 'St Helenian', value: 'St Helenian', de: 'St. Helenian' },
	{ en: 'St Lucian', value: 'St Lucian', de: 'St. Lucian' },
	{ en: 'Stateless', value: 'Stateless', de: 'Staatenlos' },
	{ en: 'Sudanese', value: 'Sudanese', de: 'Sudanesen' },
	{ en: 'Surinamese', value: 'Surinamese', de: 'Surinam' },
	{ en: 'Swazi', value: 'Swazi', de: 'Swazi' },
	{ en: 'Swedish', value: 'Swedish', de: 'Schwedisch' },
	{ en: 'Swiss', value: 'Swiss', de: 'Schweizerisch' },
	{ en: 'Syrian', value: 'Syrian', de: 'Syrisch' },
	{ en: 'Taiwanese', value: 'Taiwanese', de: 'Taiwanese' },
	{ en: 'Tajik', value: 'Tajik', de: 'Tadschikisch' },
	{ en: 'Tanzanian', value: 'Tanzanian', de: 'Tansania' },
	{ en: 'Thai', value: 'Thai', de: 'Thai' },
	{ en: 'Togolese', value: 'Togolese', de: 'Togoisch' },
	{ en: 'Tongan', value: 'Tongan', de: 'Tonganisch' },
	{ en: 'Trinidadian', value: 'Trinidadian', de: 'Trinidad' },
	{ en: 'Tristanian', value: 'Tristanian', de: 'Tristanian' },
	{ en: 'Tunisian', value: 'Tunisian', de: 'Tunesisch' },
	{ en: 'Turkish', value: 'Turkish', de: 'Türkisch' },
	{ en: 'Turkmen', value: 'Turkmen', de: 'Turkmenen' },
	{
		en: 'Turks and Caicos Islander',
		value: 'Turks and Caicos Islander',
		de: 'Turks- und Caicosinseln',
	},
	{ en: 'Tuvaluan', value: 'Tuvaluan', de: 'Tuvaluisch' },
	{ en: 'Ugandan', value: 'Ugandan', de: 'Ugandan' },
	{ en: 'Ukrainian', value: 'Ukrainian', de: 'Ukrainisch' },
	{ en: 'Uruguayan', value: 'Uruguayan', de: 'Uruguayisch' },
	{ en: 'Uzbek', value: 'Uzbek', de: 'Usbekisch' },
	{
		en: 'Vatican citizen',
		value: 'Vatican citizen',
		de: 'Staatsbürger des Vatikans',
	},
	{ en: 'Venezuelan', value: 'Venezuelan', de: 'Venezolanisch' },
	{ en: 'Vietnamese', value: 'Vietnamese', de: 'Vietnamesisch' },
	{ en: 'Vincentian', value: 'Vincentian', de: 'Vinzentinisch' },
	{ en: 'Wallisian', value: 'Wallisian', de: 'Walliser' },
	{ en: 'Welsh', value: 'Welsh', de: 'Walisisch' },
	{ en: 'Yemeni', value: 'Yemeni', de: 'Jemenitisch' },
	{ en: 'Zambian', value: 'Zambian', de: 'Sambisch' },
	{ en: 'Zimbabwean', value: 'Zimbabwean', de: 'Simbabwisch' },
];

export const FB_PIXEL_ID = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID;
export const FB_DOMAIN_VERIFICATION = process.env.NEXT_PUBLIC_FACEBOOK_DOMAIN_VERIFICATION;

export const pageView = () => {
	if (!window.fbq || !FB_PIXEL_ID) { return; }

	window.fbq('track', 'PageView');
};

export const event = (name, options = {}) => {
	if (!window.fbq || !FB_PIXEL_ID) { return; }

	window.fbq('track', name, options);
};

import { API_ROUTES, URLS } from '@apps/web/config/api';
import { clearAccountData } from '@apps/web/store/onboarding/createAccount';
import { clearQuestionaireData } from '@apps/web/store/onboarding/onboarding';
import { localeToUrl } from '@apps/web/utils/locale';

export type AccountUserType = {
	UserId: string;
	auth_time: string;
	emails: string;
	extension_UserRole: string;
	name: string;
	newUser: string;
	oid: string;
	sub: string;
	tfp: string;
};

interface AccountApiParams {
	redirectUrl?: string;
	locale?: string;
}

export const AccountService = {
	signup: async ({
		locale,
		redirectUrl = `${URLS.frontend}/${localeToUrl(locale)}/onboarding?screen=create-account-success`,
	}: AccountApiParams) => {
		window.location.href = API_ROUTES.ACCOUNT_SIGNUP(redirectUrl, locale);
	},

	signin: async ({
		locale,
		redirectUrl = `${URLS.frontend}/${localeToUrl(locale)}/patient-universe`,
	}: AccountApiParams) => {
		window.location.href = API_ROUTES.ACCOUNT_SIGNIN(redirectUrl, locale);
	},

	signout: async (redirectUrl: string = URLS.frontend) => {
		clearAccountData();
		clearQuestionaireData();
		window.location.href = API_ROUTES.ACCOUNT_SIGNOUT(redirectUrl);
	},

	getUser: async (): Promise<AccountUserType | undefined> => {
		try {
			const response = await fetch(`${URLS.backend}/account-module/Account/GetClaims`, {
				headers: {
					'Content-Type': 'application/json;charset=utf-8',
					Accept: 'application/json',
				},
				method: 'GET',
				credentials: 'include',
			});

			const user = await response.json();

			if (!response.ok) {
				return undefined;
			}

			return user;
		} catch (e) {
			// do nothing nothing
		}
	},
};

import { atom } from 'recoil';

export const onboardingDefaultState = {
	gender: '',
	height: '',
	weight: '',
	pregnant_or_breastfeeding: [] as string[],
	occupation: '',
	hasSupervisor: '',
	supervisorDetails: '',
	conditionName: '',
	icdCode: '',
	conditionPeriod: '',
	diagnosedByOtherDoctor: '',
	whenDiagnosed: '',
	frequencyOfComplaints: '',
	durationOfComplaints: '',
	changeOfSymptomsOverTime: [] as string[],
	specificMedicineForCondition: [] as string[],
	specificMedicineAdverseReactions: '',
	specificMedicineAdverseReactionsDetails: '',
	takingCurrentMedicine: '',
	currentMedicines: [
		{
			nameOfMedicine: '',
			sinceWhen: '',
		},
	],
	currentMedicineAdverseReactions: '',
	currentMedicineAdverseReactionsDetails: '',
	currentMedicineHowMuchHelps: '',
	previouslyTakenMedicine: '',
	previousMedicines: [
		{
			nameOfMedicine: '',
			previousMedicineAdverseReactions: '',
			previousMedicineAdverseReactionsDetails: '',
			previousMedicineHowMuchHelps: '',
		},
	],
	triedTreatments: [] as string[],
	triedTreatmentOtherMethods: '',
	otherDiagnosesPsychological: [] as { name: string; reason?: string } [],
	personalityDisorders: [] as { name: string; description: string; severity: string }[],
	otherDiagnosesPsychologicalOther: [{ nameOfDisease: '', icdCode: '' }],
	allergies: '',
	allergiesDetails: '',
	secondaryConditions: [] as string[],
	secondaryConditionDetails: '',
	secondaryConditionMedicalSupervision: '',
	otherDiagnosesPhysical: [{ nameOfDisease: '', icdCode: '' }],
	pain: undefined as undefined | number,
	mood: undefined as undefined | number,
	qualityOfLife: undefined as undefined | number,
	appetite: undefined as undefined | number,
	physicalActivity: undefined as undefined | number,
	concentration: undefined as undefined | number,
	sleep: undefined as undefined | number,
	regularlyUnableToWork: '',
	previousCannabisExperience: '',
	previousMedicalCannabisPrescribed: '',
	previousMedicalCannabisPrescribedUsedInPast: '',
	previousMedicalCannabisPrescribedProblemsWithPolice: '',
	previousMedicalCannabisPrescribedProblemsWithPoliceDetails: '',
	hasBeenAddictedToAlcohol: '',
	wasDependentOnIllegalSubstances: '',
	whatSubstances: '',
	furtherInfomation: '',
	clinic: '',
	heardAboutUsAt: '',
	heardAboutUsOtherDescription: '',
	confirmsProvidedInfomationIsCorrect: undefined,
	medicalDocumentsComments: '',
	successfulSections: [],
	lastActivePage: [0, 0],
};

export type OnboardingStateType = typeof onboardingDefaultState;

const KEY = 'onboarding';
export const onboarding = atom({
	key: KEY,
	default: onboardingDefaultState,
	effects_UNSTABLE: [
		({ setSelf, onSet }) => {
			const savedValue = localStorage.getItem(KEY);
			if (savedValue != null) {
				setSelf(JSON.parse(savedValue));
			} else {
				setSelf(onboardingDefaultState);
			}

			onSet((newValue) => {
				localStorage.setItem(KEY, JSON.stringify(newValue));
			});
		},
	],
});

export const clearQuestionaireData = () => {
	if (localStorage.getItem(KEY)) {
		localStorage.removeItem(KEY);
	}
};
